import * as React from "react";
import { useCallback, useEffect } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import Button from "@app/components/questkit/button";
import { Analytics } from "@app/analytics";
import { KioskStartView } from "./KioskStartView";
import { ScheduleStartView } from "@app/quest/start/ScheduleStartView";
import { PublicSubmissionsStartView } from "@app/quest/start/PublicSubmissionsStartView";
import { ChooseQuestStartTriggerView } from "@app/quest/start/ChooseQuestStartTriggerView";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { InfoText } from "@app/components/questkit/InfoText";
import { AddEntryCard } from "@app/quest/edit/AddEntryCard";
import { QuestStartTriggerEditData } from "@app/store/cache/questPrototypes";

interface QuestStartTriggerProps {
  startTrigger: QuestStartTriggerEditData;
  isChangingQuestType: boolean;
  setIsChangingQuestType: (isChangingQuestType: boolean) => void;
  openStartQuestModal?: () => void;
  readOnly?: boolean;
}
export const QuestStartTrigger: React.FC<QuestStartTriggerProps> = ({
  startTrigger,
  isChangingQuestType,
  setIsChangingQuestType,
  openStartQuestModal,
  readOnly = false,
}) => {
  const startChangingQuestType = useCallback(() => {
    Analytics.trackEvent("Start Changing Quest Type", {
      currentQuestType: startTrigger.type,
    });
    setIsChangingQuestType(true);
  }, [startTrigger.type, setIsChangingQuestType]);
  const onCancelChangeQuestType = useCallback(() => {
    Analytics.trackEvent("Cancel Changing Quest Type");
    setIsChangingQuestType(false);
  }, [setIsChangingQuestType]);

  const onQuestTypeSelected = useCallback(() => {
    setIsChangingQuestType(false);
  }, [setIsChangingQuestType]);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) {
      setIsChangingQuestType(false);
    }
  }, [isFocused, setIsChangingQuestType]);

  return (
    <View testID="quest-start-trigger">
      {isChangingQuestType ? (
        <ChooseQuestStartTriggerView
          context="CHANGE_TYPE"
          onQuestTypeSelected={onQuestTypeSelected}
          onCancelChangeQuestType={onCancelChangeQuestType}
          startTriggerId={startTrigger.id}
        />
      ) : (
        <>
          {startTrigger.type === null ? (
            <>
              <AddEntryCard
                text="Add Start Settings"
                icons={["kiosk", "play", "link", "schedule-send"]}
                onPress={startChangingQuestType}
                testID="add-quest-start-trigger-button"
              />
              <StyledInfoText
                text="Please add start settings to use this Quest!"
                infoType="warning"
              />
            </>
          ) : startTrigger.type === "KIOSK" ? (
            <KioskStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "SCHEDULE" ? (
            <ScheduleStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "PUBLIC" ? (
            <PublicSubmissionsStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "BASIC" ? (
            readOnly ? (
              <StartTriggerSummary
                text={"Starts Manually"}
                icon={QUEST_TYPES["BASIC"].icon}
              />
            ) : (
              <StartTriggerCard
                title={QUEST_TYPES["BASIC"].name}
                icon={QUEST_TYPES["BASIC"].icon}
                startChangingQuestType={startChangingQuestType}
              >
                <Button
                  onPress={openStartQuestModal}
                  title={"Run Quest"}
                  testID="open-start-quest-modal-button"
                  buttonType="primary"
                />
              </StartTriggerCard>
            )
          ) : (
            <View>
              <Text size="medium" $warning={true}>
                It seems there&apos;s something new to see here. Please update /
                reload your app to experience some new magics!
              </Text>
            </View>
          )}
        </>
      )}
    </View>
  );
};

const StyledInfoText = styled(InfoText)`
  margin-horizontal: 20px;
  margin-bottom: 16px;
  margin-top: 0;
  align-self: center;
`;
export const Section = styled.View`
  margin-bottom: 20px;
`;
