import { createTabNavigator } from "@app/quest/TabNavigator";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { QuestEditScreen } from "@app/quest/edit/QuestEditScreen";
import {
  FilterIdsByGroupId,
  QuestRunsScreen,
} from "@app/quest/run/QuestRunsScreen";
import QKModal from "@app/components/modal";
import TemplateOptionsDialog from "@app/components/modal/templateOptionsDialog";
import { fetchQuest } from "@app/util/client/requests/quests";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import CreateQuest from "@app/components/modal/createTemplate";
import {
  HeaderIcon,
  PlaceholderHeaderIcon,
} from "@app/navigation/components/HeaderIcon";
import { Analytics } from "@app/analytics";
import Icon from "@app/components/icon";
import { View } from "react-native";
import { useMultiTapToggler } from "@app/quest/edit/useMultiTapToggler";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import { QuestContext, useQuestContext } from "./QuestContext";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import Loader from "@app/components/animated/loader";
import { selectQuestById } from "@app/store/cache/quests";
import { useRequest } from "@app/util/client/requests";
import { useScreenHasBeenFocusedAtLeastOnce } from "@app/util/useScreenHasBeenFocusedAtLeastOnce";
import type { ManageQuestViewMode } from "@app/components/screen/quest/QuestManageView";

const QKTabNavigator = createTabNavigator();

export type QuestTabsParamList = {
  QuestEdit: {
    questPrototypeId?: string;
    initialViewMode?: ManageQuestViewMode;
    advancedMode?: boolean;
  };
  QuestRuns: {
    filters?: FilterIdsByGroupId;
  };
};

export const MainQuestScreen: React.FC = () => {
  const route = useAppRoute<"Template">();
  const navigation = useAppNavigation();
  const { templateId, advancedMode: advancedModeParam } = route.params;
  // Get Template Data
  useRequest(fetchQuest(templateId));
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(advancedModeParam || false);

  const rootQuestPrototypeId = useAppSelector((state) =>
    templateId
      ? selectQuestById(state, templateId)?.currentQuestPrototypeId ?? undefined
      : undefined
  );

  useQuestPrototype(rootQuestPrototypeId);

  const questName = useAppSelector((state) => {
    if (!rootQuestPrototypeId) {
      return undefined;
    }
    return selectQuestPrototypeById(state, rootQuestPrototypeId)!.name;
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: questName ?? "",
      headerRight: () =>
        rootQuestPrototypeId ? (
          <HeaderIcon
            testID="open-quest-options-dialog"
            onPress={() => {
              Analytics.trackEvent("Open Quest Options Dialog");
              setShowOptionsModal(true);
            }}
          >
            <Icon icon="gear" />
          </HeaderIcon>
        ) : (
          <PlaceholderHeaderIcon />
        ),
    });
  }, [rootQuestPrototypeId, navigation, questName]);

  const snackbar = useContext(SnackbarContext);

  const { onStartShouldSetResponderCapture } = useMultiTapToggler({
    tapCount: 10,
    timeWindow: 2.5 * 1000,
    onToggle: () => {
      setAdvancedMode((prev) => !prev);
      snackbar.sendMessage(
        `Advanced mode ${advancedMode ? "disabled" : "enabled"}.`
      );
    },
  });

  const questContext = useMemo(() => {
    if (!rootQuestPrototypeId) {
      return {
        isReady: false,
        templateId,
        advancedMode,
      } as const;
    }
    return {
      isReady: true,
      templateId,
      advancedMode,
      currentQuestPrototypeId: rootQuestPrototypeId,
      name: questName!,
    } as const;
  }, [advancedMode, rootQuestPrototypeId, questName, templateId]);

  return (
    <View
      style={{ flex: 1 }}
      onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
    >
      <QuestContext.Provider value={questContext}>
        <QKTabNavigator.Navigator>
          <QKTabNavigator.Group navigationKey={templateId}>
            <QKTabNavigator.Screen
              name="QuestEdit"
              component={
                questContext.isReady
                  ? QuestEditScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={{
                // browser tab title
                title: questName ?? "Quest Create",
                tabTitle: "Template",
                tabTitleIcon: "pen",
                testID: "QuestEditTab",
              }}
            />
            <QKTabNavigator.Screen
              name="QuestRuns"
              component={QuestRunsScreen}
              options={{
                // browser tab title
                title: questName ?? "Quest Runs",
                tabTitle: "Runs",
                tabTitleIcon: "item",
                testID: "QuestRunsTab",
              }}
            />
          </QKTabNavigator.Group>
        </QKTabNavigator.Navigator>

        {!questContext.isReady ? null : (
          <>
            <QKModal
              showModal={showOptionsModal}
              setShowModal={setShowOptionsModal}
              title="Default Run Options"
            >
              <TemplateOptionsDialog
                setShowOptionsModal={setShowOptionsModal}
                setShowDuplicateModal={setShowDuplicateModal}
              />
            </QKModal>

            <QKModal
              showModal={showDuplicateModal}
              setShowModal={setShowDuplicateModal}
              title="Duplicate Quest"
            >
              <CreateQuest
                createButtonText={"Clone"}
                defaultName={questName}
                sourceTemplateId={templateId}
                setShowModal={setShowDuplicateModal}
              />
            </QKModal>
          </>
        )}
      </QuestContext.Provider>
    </View>
  );
};

const CenteredLoadingIndicator: React.FC = () => (
  <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
    <Loader />
  </View>
);

const QuestEditScreenWrapper: React.FC = () => {
  const { currentQuestPrototypeId } = useQuestContext();
  const questPrototypeId = useAppRoute<"QuestEdit">().params?.questPrototypeId;
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = questPrototypeId ?? currentQuestPrototypeId;
  return hasBeenFocused ? (
    <QuestEditScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};
