import React, { PropsWithChildren, useMemo } from "react";
import { QuestViewContext } from "../QuestViewContext";
import { selectLoggedInUserId } from "@app/store/auth";
import { useAppSelector } from "@app/store";
import { useChangeTracker } from "@app/quest/edit/useChangeTracker";
import {
  selectEditableQuestPrototypeDetailsById,
  selectQuestPrototypeById,
} from "@app/store/cache/questPrototypes";
import isEqual from "react-fast-compare";
import { sentry } from "@app/util/sentry";

interface PreviewQuestViewContextProviderProps {
  templateId: string;
  questPrototypeId: string;
}

export const PreviewQuestViewContextProvider: React.FC<
  PropsWithChildren<PreviewQuestViewContextProviderProps>
> = ({ templateId, questPrototypeId, children }) => {
  const questPrototypeFromServer = useAppSelector(
    (state) =>
      selectEditableQuestPrototypeDetailsById(state, questPrototypeId)!,
    isEqual
  );

  const { useValueWithChanges } = useChangeTracker(questPrototypeFromServer);
  const isOwner = useAppSelector((state) => {
    return (
      selectLoggedInUserId(state) ===
      selectQuestPrototypeById(state, questPrototypeId!)?.ownerId
    );
  });

  const staticContext = useMemo(
    // Needed to match fields provided by 'MANAGE' view context
    () => ({
      save: () => Promise.resolve(true),
      isSaving: false,
      errorMap: {},
      touchedMap: {},
      useScopedValidationErrors: () => [],
      setFieldTouched: () => {
        const msg = "setFieldTouched called in PREVIEW viewContext";
        console.warn(msg);
        sentry.captureMessage(msg, {
          contexts: {
            Data: {
              templateId,
              questPrototypeId,
            },
          },
        });
      },
      markAllFieldsAsTouched: () => {
        const msg = "markAllFieldsAsTouched called in PREVIEW viewContext";
        console.warn(msg);
        sentry.captureMessage(msg, {
          contexts: {
            Data: {
              templateId,
              questPrototypeId,
            },
          },
        });
      },
      addChange: () => {
        const msg = "addChange called in PREVIEW viewContext";
        console.warn(msg);
        sentry.captureMessage(msg, {
          contexts: {
            Data: {
              templateId,
              questPrototypeId,
            },
          },
        });
      },
      setValidationContext: () => {
        const msg = "setValidationContext called in PREVIEW viewContext";
        console.warn(msg);
        sentry.captureMessage(msg, {
          contexts: {
            Data: {
              templateId,
              questPrototypeId,
            },
          },
        });
      },
      advancedMode: false,
    }),
    [questPrototypeId, templateId]
  );

  const context = useMemo(() => {
    return {
      viewContext: "PREVIEW",
      templateId,
      questPrototypeId,
      useQuestPrototypeWithChanges: useValueWithChanges,
      isOwner,
      ...staticContext,
    } as const;
  }, [
    templateId,
    questPrototypeId,
    useValueWithChanges,
    isOwner,
    staticContext,
  ]);
  return (
    <QuestViewContext.Provider value={context}>
      {children}
    </QuestViewContext.Provider>
  );
};
