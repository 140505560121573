import { updateRewards } from "@app/quest/edit/completionActions";
import { updateItems } from "@app/quest/edit/items";
import { updateQuestPrototype } from "@app/util/client/requests/quests";
import {
  type QMErrorResponse,
  type QuestPrototypeDetail,
} from "@questmate/openapi-spec";
import { ChangeSet } from "@app/quest/edit/useChangeTracker";
import { EditableQuestPrototypeDetails } from "@app/store/cache/questPrototypes";
import type { ValidationError } from "@questmate/common";

export type SuccessResult = {
  success: true;
};
export type FailureResult = {
  success: false;
  errors: ValidationError[];
};
export type SaveQuestPrototypeResult = SuccessResult | FailureResult;

export async function saveQuestPrototype(
  questPrototypeId: string,
  changeSet: ChangeSet<EditableQuestPrototypeDetails>
): Promise<SaveQuestPrototypeResult> {
  const updateIfChanged = updatePendingChanges(questPrototypeId, changeSet);

  // TODO: Avoid letting responses overwrite each other in the cache.
  await Promise.all([
    updateIfChanged(["introText"], updateIntroText),
    updateIfChanged(["itemsById"], updateItems),
    updateIfChanged(["rewardsById"], updateRewards),
  ]);

  return { success: true };
}

type SaveFunction<T> = (
  id: string,
  changes: T
) => Promise<UpdateQuestPrototypeFieldResponse>;

const updatePendingChanges =
  (id: string, changeSet: ChangeSet<EditableQuestPrototypeDetails>) =>
  <K extends keyof EditableQuestPrototypeDetails>(
    keys: K[],
    saveChanges: SaveFunction<Pick<EditableQuestPrototypeDetails, K>>
  ): Promise<void | UpdateQuestPrototypeFieldResponse> => {
    const updatedFields = keys.reduce((acc, key) => {
      const changes = changeSet.getChangesForPath([key], "pending");
      if (changes.length > 0) {
        acc[key] = changeSet.valueWithChanges[key];
      }
      return acc;
    }, {} as { [P in K]: EditableQuestPrototypeDetails[P] });

    const thereAreChangesToSave = Object.keys(updatedFields).length > 0;
    if (thereAreChangesToSave) {
      return saveChanges(id, updatedFields);
    }
    return Promise.resolve();
  };

export const updateIntroText = async (
  questPrototypeId: string,
  { introText }: Pick<EditableQuestPrototypeDetails, "introText">
): Promise<UpdateQuestPrototypeFieldResponse> => {
  return updateQuestPrototype(questPrototypeId, {
    introText,
  })
    .then((updatedQuestPrototype) => {
      return {
        success: true,
        updatedQuestPrototype,
      } as SuccessFieldResponse;
    })
    .catch((e) => {
      console.warn(`Failed to save intro text:`, e);
      return {
        success: false,
        error: e,
      };
    });
};

export type SuccessFieldResponse = {
  success: true;
  updatedQuestPrototype: QuestPrototypeDetail;
};
export type FailureFieldResponse = {
  success: false;
  error: QMErrorResponse;
};

export type UpdateQuestPrototypeFieldResponse =
  | SuccessFieldResponse
  | FailureFieldResponse;
