import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import styled from "styled-components/native";
import { useModal } from "@app/components/modal/ModalManager";
import QKModal from "@app/components/modal";
import StartQuestDialog from "@app/components/modal/startQuestDialog";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { Analytics } from "@app/analytics";
import * as Clipboard from "expo-clipboard";
import { sentry } from "@app/util/sentry";
import { useLink } from "@app/util/link.utils";
import {
  SplitButton,
  SplitButtonProps,
} from "@app/components/questkit/SplitButton";
import { QrCodeModalView } from "@app/quest/start/QrCodeModalView";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { useAppSelector } from "@app/store";
import { selectQuestStartTriggerByComboId } from "@app/store/cache/questStartTriggers";
import isEqual from "react-fast-compare";

interface OmnipresentStartButtonProps {
  templateId: string;
  questPrototypeId: string;
  startTriggerId: string;
}
const _OmnipresentStartButton: React.FC<OmnipresentStartButtonProps> = ({
  templateId,
  questPrototypeId,
  startTriggerId,
}) => {
  const snackbar = useContext(SnackbarContext);

  const questPrototypeName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name
  );

  const startTrigger = useAppSelector((state) => {
    const st = selectQuestStartTriggerByComboId(
      state,
      `${questPrototypeId}:${startTriggerId}`
    );
    return st
      ? {
          type: st.type,
          config: st.config,
        }
      : undefined;
  }, isEqual);

  const { openModal: openStartQuestModal } = useModal(
    ({ showModal, setShowModal }) =>
      !questPrototypeName ? (
        <></>
      ) : (
        <QKModal
          testID="start-quest-modal"
          showModal={showModal}
          setShowModal={setShowModal}
          title={
            startTrigger?.type === "KIOSK"
              ? "Logout & Start Kiosk Mode"
              : "New Quest Run"
          }
        >
          <StartQuestDialog
            templateId={templateId}
            rootQuestPrototypeId={questPrototypeId}
            name={questPrototypeName}
            setShowStartQuestModal={setShowModal}
          />
        </QKModal>
      )
  );

  const publicUrl = startTrigger?.config?.publicUrl;
  const copyPublicUrlToClipboard = useCallback(() => {
    if (!publicUrl) {
      console.error("Missing share URL, cannot copy to clipboard.");
      return;
    }
    Analytics.trackEvent("Copy Public Url to Clipboard", {
      questPrototypeId: questPrototypeId,
      actionLocation: "Omnipresent Start Button",
    });
    Clipboard.setStringAsync(publicUrl)
      .then(() => {
        snackbar.sendMessage("Link copied to clipboard");
      })
      .catch((e) => {
        snackbar.sendMessage(
          "Failed to copy link to clipboard",
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            message: "Failed to copy Public Quest link to clipboard",
            publicUrl: publicUrl,
            startTrigger,
          },
        });
      });
  }, [publicUrl, questPrototypeId, snackbar, startTrigger]);

  const publicId = startTrigger?.config?.publicId;
  const goToPublicUrl = useLink(
    publicId
      ? { screen: "PublicAssignment", params: { id: publicId! } }
      : undefined,
    {
      onPressHook: () =>
        Analytics.trackEvent("Open Public Url", {
          questPrototypeId: questPrototypeId,
        }),
    }
  );

  const { openModal: openQrCodeModal, closeModal: closeQrCodeModal } = useModal(
    ({ showModal, setShowModal }) =>
      !questPrototypeName ? (
        <></>
      ) : (
        <QKModal
          testID="qr-code-modal"
          showModal={showModal}
          setShowModal={setShowModal}
          title="Scan to Start"
        >
          <QrCodeModalView
            filenamePrefix={questPrototypeName}
            url={publicUrl!}
            onDownload={onDownloadQrCode}
          />
        </QKModal>
      )
  );
  const onDownloadQrCode = useCallback(() => {
    Analytics.trackEvent("Download QR Code", {
      questPrototypeId: questPrototypeId,
    });
    closeQrCodeModal();
  }, [closeQrCodeModal, questPrototypeId]);

  const actions = useMemo((): SplitButtonProps["actions"] => {
    switch (startTrigger?.type) {
      case "BASIC":
        return [
          {
            title: "Run Quest",
            testID: "primary-start-button",
            buttonType: "action",
            onPress: () => openStartQuestModal(),
          },
        ];
      case "SCHEDULE":
        return [
          {
            title: "Run Once Now",
            testID: "primary-start-button",
            buttonType: "action",
            onPress: () => openStartQuestModal(),
          },
        ];
      case "PUBLIC":
        return [
          {
            title: "Copy Share Link",
            testID: "primary-start-button",
            buttonType: "action",
            onPress: () => copyPublicUrlToClipboard(),
          },
          {
            title: "Run Once Now",
            buttonType: "secondary",
            onPress: goToPublicUrl,
          },
          {
            title: "Show QR Code",
            buttonType: "secondary",
            onPress: openQrCodeModal,
          },
        ];
      case "KIOSK":
        return [
          {
            title: "Enter Kiosk Mode",
            testID: "primary-start-button",
            buttonType: "action",
            onPress: () => openStartQuestModal(),
          },
          {
            title: "Run Once Now",
            buttonType: "secondary",
            onPress: goToPublicUrl,
          },
          {
            title: "Show QR Code",
            buttonType: "secondary",
            onPress: openQrCodeModal,
          },
        ];
      default:
        return [];
    }
  }, [
    copyPublicUrlToClipboard,
    goToPublicUrl,
    openQrCodeModal,
    openStartQuestModal,
    startTrigger?.type,
  ]);

  return !startTrigger ? null : (
    <OmnipresentStartButtonContainer>
      <SplitButton testID={"omnipresent-start-button"} actions={actions} />
    </OmnipresentStartButtonContainer>
  );
};

export const OmnipresentStartButton = React.memo(
  _OmnipresentStartButton,
  isEqual
);
OmnipresentStartButton.displayName = "OmnipresentStartButton";

const OmnipresentStartButtonContainer = styled.View`
  flex: 1;
`;
