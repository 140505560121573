import React, { useEffect } from "react";

import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { QuestPublicPreviewView } from "@app/components/screen/quest/QuestPublicPreviewView";

import PlaceholderView from "@app/components/screen/PlaceholderView";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { Analytics } from "@app/analytics";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { ScreenContainer } from "@app/screens/ScreenContainer";
import { PreviewQuestViewContextProvider } from "@app/quest/edit/PreviewQuestViewContextProvider";

export const PublicTemplatePreviewScreen: React.FC = () => {
  const { questPrototypeId, screenshot } =
    useAppRoute<"PublicTemplatePreview">().params || {};
  const { questPrototype, error, isLoading, refresh } =
    useQuestPrototype(questPrototypeId);
  useEffectOnce(() => {
    Analytics.trackEvent("View Public Template Preview", { questPrototypeId });
  });

  const navigation = useAppNavigation();
  const questName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name || ""
  );
  useEffect(() => {
    navigation.setOptions({
      headerTitle: questName,
    });
  }, [navigation, questName]);

  if (!questPrototype) {
    if (error) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: isLoading,
                onPress: refresh,
              },
            ]}
          />
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <ScreenContainer>
      <PreviewQuestViewContextProvider
        templateId={questPrototype.quest.id}
        questPrototypeId={questPrototypeId}
      >
        <QuestPublicPreviewView
          viewMode={
            screenshot
              ? "PUBLIC_TEMPLATE_SCREENSHOT"
              : "PUBLIC_TEMPLATE_PREVIEW"
          }
        />
      </PreviewQuestViewContextProvider>
    </ScreenContainer>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
