import React, { useEffect, useMemo } from "react";
import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { useAppSelector } from "@app/store";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { AccessDeniedView } from "@app/components/screen/AccessDeniedView";
import { selectLoggedInUser } from "@app/store/cache/users";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";

export const OldAdminQuestRunRedirectScreen: React.FC = () => {
  const navigation = useAppNavigation<"OldAdminQuestRunRedirect">();
  const route = useAppRoute<"OldAdminQuestRunRedirect">();
  const {
    prototypeId: questPrototypeId,
    instanceId,
    assignmentId,
    loginId,
  } = route.params;
  const {
    questPrototype,
    isLoading: loadingQuestPrototype,
    error: questPrototypeError,
    refresh: reloadQuestPrototype,
  } = useQuestPrototype(questPrototypeId, { refreshInterval: 10000 });
  const assignments = useMemo(
    () => questPrototype?.assignments || [],
    [questPrototype?.assignments]
  );
  const isLoggedInUserDataLoaded = useAppSelector(
    (state) =>
      selectLoggedInUser(state)?.email || selectLoggedInUser(state)?.phone
  );

  let selectedAssignmentId = assignmentId;
  if (!selectedAssignmentId && assignments.length > 0) {
    if (instanceId) {
      const assignment = assignments.find(
        ({ formInstance }) => formInstance?.id === instanceId
      );
      if (assignment) {
        selectedAssignmentId = assignment.id;
      }
    }

    if (!selectedAssignmentId) {
      selectedAssignmentId = assignments[0].id;
    }
  }
  const selectedAssignment = assignments.find(
    ({ id }) => id === selectedAssignmentId
  );

  const questInstanceId =
    questPrototype?.sharedInstance?.id ?? selectedAssignment?.formInstance?.id;

  useEffect(() => {
    if (questInstanceId) {
      navigation.replace("AdminQuestRun", {
        templateId: questPrototype?.quest?.id as string,
        questInstanceId,
        loginId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questInstanceId]);

  if (questPrototypeError && isLoggedInUserDataLoaded) {
    console.error(
      "Error received while loading Quest instance",
      questPrototypeError
    );
    const userDoesNotHavePermission = questPrototypeError.status === 403;
    return (
      <ViewContainer>
        {userDoesNotHavePermission ? (
          <AccessDeniedView reload={reloadQuestPrototype} loginId={loginId} />
        ) : (
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: loadingQuestPrototype,
                onPress: reloadQuestPrototype,
              },
            ]}
          />
        )}
      </ViewContainer>
    );
  }
  return (
    <ViewContainer>
      <Loader />
    </ViewContainer>
  );
};

const ViewContainer = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
