import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import SafeAreaView from "react-native-safe-area-view";
import styled from "styled-components/native";
import { View } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import QKModal from "@app/components/modal";
import {
  EditLevel,
  QuestView,
  ReviewButtonBehavior,
  StatusMessageBehavior,
  SubmitButtonBehaviour,
} from "@app/components/screen/quest/common/questView";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import Icon from "@app/components/icon";
import { useCompletionActions } from "@app/quest/edit/completionActions";
import { useItems } from "@app/quest/edit/items";
import Button from "@app/components/questkit/button";
import { Boundary } from "../boundary";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import CreateQuest from "@app/components/modal/createTemplate";
import QKScrollView from "@app/components/questkit/ScrollView";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";

type PublicPreviewQuestViewMode =
  // For public template clone screen
  | "PUBLIC_TEMPLATE_PREVIEW"
  // For creating template library screenshots
  | "PUBLIC_TEMPLATE_SCREENSHOT";

interface QuestEditAndPreviewViewProps {
  viewMode?: PublicPreviewQuestViewMode;
}

export const QuestPublicPreviewView: React.FC<QuestEditAndPreviewViewProps> = ({
  viewMode = "PUBLIC_TEMPLATE_PREVIEW",
}) => {
  const userIsLoggedIn = Boolean(getLoggedInUserId());

  const { templateId, questPrototypeId, useQuestPrototypeWithChanges } =
    useQuestViewContext(["PREVIEW"]);
  const questPrototype = useQuestPrototypeWithChanges((qp) => {
    return {
      id: qp.id,
      name: qp.name,
      startTriggers: qp.startTriggerIds?.map(
        (id) => qp.startTriggersById![id]!
      ),
      introText: qp.introText,
      parentItemPrototypeId: qp.parentItemPrototypeId,
    };
  });

  const navigation = useAppNavigation();
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const snackbarContext = useContext(SnackbarContext);
  const onBackPress = useCallback(async () => {
    navigation.goBack();
  }, [navigation]);

  const headerLeft = useMemo(
    () =>
      function HeaderLeft() {
        return (
          <HeaderIcon
            activeOpacity={0.8}
            onPress={onBackPress}
            testID="back-button"
          >
            <Icon icon={"chevron-left"} size={32} />
          </HeaderIcon>
        );
      },
    [onBackPress]
  );

  useLayoutEffect(() => {
    if (viewMode === "PUBLIC_TEMPLATE_PREVIEW") {
      navigation.setOptions({
        headerLeft: userIsLoggedIn ? headerLeft : () => null,
      });
    } else if (viewMode === "PUBLIC_TEMPLATE_SCREENSHOT") {
      navigation.setOptions({
        headerShown: false,
      });
    }
  }, [navigation, viewMode, headerLeft, userIsLoggedIn]);

  const {
    completionActions,
    onCompletionActionAdded,
    onCompletionActionDelete,
    onCompletionActionReorder,
    onCompletionActionChange,
    onCompletionActionValidationContextChange,
    onCompletionActionTouched,
  } = useCompletionActions();

  const { items, onItemAdded, onItemDelete, onItemReorder, onItemChange } =
    useItems();

  const startTriggers = useMemo(() => {
    if (questPrototype.parentItemPrototypeId) {
      // is subquest
      return;
    }
    return questPrototype.startTriggers;
  }, [questPrototype.parentItemPrototypeId, questPrototype.startTriggers]);

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questPrototype",
        uploadContextId: questPrototypeId,
        contexts: [
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <StyledScrollView keyboardShouldPersistTaps="always">
        <SafeAreaView
          forceInset={{
            top: viewMode === "PUBLIC_TEMPLATE_PREVIEW" ? "always" : "never",
            bottom: "always",
          }}
        >
          <View
            onStartShouldSetResponderCapture={() => true}
            onResponderTerminationRequest={
              () => true /* do not show snackbar message when touch scrolling */
            }
            onResponderEnd={() => {
              snackbarContext.sendMessage('Press "Use Quest" to continue.');
            }}
          >
            <QuestView
              startTriggers={startTriggers}
              introText={questPrototype.introText}
              items={items}
              onItemChange={onItemChange}
              onItemAdded={onItemAdded}
              onItemDelete={onItemDelete}
              onItemReorder={onItemReorder}
              loading={false}
              completed={false}
              submitButtonTitle={"Submit"}
              itemsEditLevel={EditLevel.Readonly}
              completionActionsEditLevel={EditLevel.Readonly}
              submitButtonBehaviour={
                viewMode === "PUBLIC_TEMPLATE_SCREENSHOT"
                  ? SubmitButtonBehaviour.Submittable
                  : SubmitButtonBehaviour.Hidden
              }
              reviewButtonBehavior={ReviewButtonBehavior.Hidden}
              statusMessageBehavior={StatusMessageBehavior.Hidden}
              canEditIntroText={false}
              sectionHeaders={{
                startTriggers: "HIDDEN",
                items: "HIDDEN",
                completionActions:
                  completionActions.length > 0 ? "MINIMAL" : "HIDDEN",
              }}
              completionActions={completionActions}
              onCompletionActionChange={onCompletionActionChange}
              onCompletionActionAdded={onCompletionActionAdded}
              onCompletionActionDelete={onCompletionActionDelete}
              onCompletionActionReorder={onCompletionActionReorder}
              onCompletionActionValidationContextChange={
                onCompletionActionValidationContextChange
              }
              onCompletionActionTouched={onCompletionActionTouched}
            />
            <View style={{ height: 80 }} />
          </View>
        </SafeAreaView>
      </StyledScrollView>

      {viewMode === "PUBLIC_TEMPLATE_SCREENSHOT" ? null : (
        <ActionButtonBottomPanel mode={viewMode}>
          <Boundary>
            <ActionButtonRow>
              {viewMode === "PUBLIC_TEMPLATE_PREVIEW" ? (
                <ActionButton
                  onPress={async () => {
                    Analytics.trackEvent("Press Use Template", {
                      templateId: templateId,
                      questPrototypeId: questPrototypeId,
                      userIsLoggedIn,
                    });
                    if (userIsLoggedIn) {
                      // if (
                      //   billingSummaryData?.subscriptionStatus === "INACTIVE" &&
                      //   Platform.OS === "web"
                      // ) {
                      //   navigation.navigate("Billing");
                      // } else {
                      setShowCreateTemplateModal(true);
                      // }
                    } else {
                      navigation.navigateToLoginThenRedirectTo(
                        "TemplateCreate",
                        {
                          sourceTemplateId: templateId,
                        }
                      );
                    }
                  }}
                  title={"Use Quest"}
                />
              ) : null}
            </ActionButtonRow>
          </Boundary>
        </ActionButtonBottomPanel>
      )}

      <QKModal
        showModal={showCreateTemplateModal}
        setShowModal={setShowCreateTemplateModal}
        title="Create Quest From Template"
      >
        <CreateQuest
          createButtonText="Create Quest"
          setShowModal={setShowCreateTemplateModal}
          sourceTemplateId={templateId}
          defaultName={questPrototype.name}
        />
      </QKModal>
    </MediaContext.Provider>
  );
};

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const ActionButtonBottomPanel = styled.View<{
  mode: PublicPreviewQuestViewMode;
}>`
  position: absolute;
  justify-content: center;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: ${({ theme }) => theme.actionBar.background};
`;

const ActionButton = styled(Button)`
  flex: 1;
`;

const ActionButtonRow = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-horizontal: 20px;
  gap: 10px;
`;
