import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  interpolateColor,
  measure,
  runOnJS,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import Text from "@app/components/questkit/text";
import styled, { ThemeContext } from "styled-components/native";
import * as Haptics from "expo-haptics";
import Icon, { IconIdentifier } from "@app/components/icon";

import { WaveIndicator } from "react-native-indicators";
import { sentry } from "@app/util/sentry";
import { GestureResponderEvent } from "react-native/Libraries/Types/CoreEventTypes";
import { OnLinkPress } from "@app/util/link.utils";
import BasePressable from "@app/components/questkit/BasePressable";
import { ButtonType } from "@app/themes/QuestmateTheme";

export interface ButtonProps<T = unknown> {
  title: string;
  icon?: IconIdentifier;
  onPress?:
    | OnLinkPress
    | ((
        event:
          | React.MouseEvent<HTMLAnchorElement, MouseEvent>
          | GestureResponderEvent
      ) => Promise<T> | void)
    | null;
  loading?: boolean;
  success?: boolean;
  onSuccess?: (successData: T) => (() => void) | void;
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonWrapperStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  loadingPosition?: "center" | "right";
  testID?: string;
}

const ICON_SIZE = 32;
const MIN_WIDTH = 40;

function Button<T>({
  title,
  icon,
  onPress,
  loading,
  buttonType = "primary",
  loadingPosition,
  success,
  onSuccess,
  style = {},
  buttonWrapperStyle = {},
  disabled,
  testID,
}: ButtonProps<T>): React.ReactElement {
  loadingPosition = loadingPosition || "center";
  const theme = useContext(ThemeContext);

  const onSuccessCleanupRef = useRef<(() => void) | void>();

  const onPressHandler = useMemo(() => {
    const _onPressHandler = async (event: GestureResponderEvent) => {
      sentry.addBreadcrumb({
        category: "ui.click",
        message: `User clicked "${title} button"`,
        level: "info",
      });

      if (Platform.OS === "ios") {
        void Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
      }
      if (onPress) {
        const onPressPromise = onPress(event);
        if (onPressPromise) {
          onPressPromise
            .then((data) => {
              if (Platform.OS === "ios") {
                void Haptics.notificationAsync(
                  Haptics.NotificationFeedbackType.Success
                );
              }
              if (onSuccess) {
                onSuccessCleanupRef.current = onSuccess(data);
              }
            })
            .catch(() => {
              /* do nothing for now */
            });
        }
      }
    };
    if (onPress && "linkProps" in onPress) {
      _onPressHandler.linkProps = onPress.linkProps;
    }
    return _onPressHandler;
  }, [onPress, onSuccess, title]);

  useEffect(() => {
    return () => {
      if (onSuccessCleanupRef.current) {
        onSuccessCleanupRef.current();
      }
    };
  }, []);

  type ButtonState =
    | "INITIAL"
    | "ANIMATING_TO_NORMAL"
    | "NORMAL"
    | "ANIMATING_TO_CIRCLE"
    | "CIRCLE";
  const [buttonState, setButtonState] = useState<ButtonState>("INITIAL");

  useEffect(() => {
    if (loading || success) {
      if (buttonState === "INITIAL") {
        setButtonState("CIRCLE");
      } else if (buttonState !== "CIRCLE") {
        setButtonState("ANIMATING_TO_CIRCLE");
      }
    } else if (buttonState === "INITIAL") {
      setButtonState("NORMAL");
    } else {
      setButtonState("ANIMATING_TO_NORMAL");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, loading]);

  // Shared value for animation of button to circle and back
  // 0 - Circle shape
  // 1 - Normal shape
  const animation = useSharedValue(loading || success ? 0 : 1);

  const previousButtonStateRef = useRef(buttonState);
  useEffect(() => {
    previousButtonStateRef.current = buttonState;

    try {
      if (buttonState === "ANIMATING_TO_CIRCLE") {
        runOnUI((finishedCallback) => {
          // Measurement needs to be taken on UI thread.
          // If we want to ensure it is present before animating, we must initiate the animation here as well.
          if (animation.value === 1 || buttonTextFullWidth.value === 0) {
            const measurement = measure(animatedRef);
            if (measurement !== null) {
              buttonTextFullWidth.value = measurement.width;
            }
          }
          animation.value = withTiming(
            0,
            {
              duration: 300,
              easing: Easing.inOut(Easing.ease),
              // reduceMotion: ReduceMotion.System,
            },
            (f) => runOnJS(finishedCallback)(f)
          );
        })((finished: boolean) => {
          if (finished) {
            if (buttonState === previousButtonStateRef.current) {
              setButtonState("CIRCLE");
            }
          }
        });
      } else if (buttonState === "ANIMATING_TO_NORMAL") {
        runOnUI((finishedCallback) => {
          // Animate to circle must be initiated from UI thread.
          // In order for us to successfully interrupt the animation and animate back to normal,
          // we must also initiate from the UI thread...
          animation.value = withTiming(
            1,
            {
              duration: 300,
              easing: Easing.inOut(Easing.ease),
              // reduceMotion: ReduceMotion.System,
            },
            (f) => runOnJS(finishedCallback)(f)
          );
        })((finished: boolean) => {
          if (finished) {
            if (buttonState === previousButtonStateRef.current) {
              setButtonState("NORMAL");
            }
          }
        });
      } else {
        runOnUI((newValue) => {
          // Animate to circle must be initiated from UI thread.
          // In order for us to successfully interrupt the animation and set the animation value,
          // we must also perform the operation from the UI thread...
          animation.value = newValue;
        })(loading || success ? 0 : 1);
      }
    } catch (error) {
      console.error("Error occurred when animating button", error);
      sentry.captureException(error, {
        extra: {
          buttonState,
          previousButtonStateRefCurrent: previousButtonStateRef.current,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonState]);

  const availableWidth = (style as ViewStyle)?.width || "100%";
  const percentageAvailableWidth = useMemo((): number | null => {
    try {
      return typeof availableWidth === "string"
        ? parseInt(availableWidth.replace("%", ""))
        : null;
    } catch (_e) {
      return null;
    }
  }, [availableWidth]);

  const animatedRef = useAnimatedRef<View>();
  const buttonTextFullWidth = useSharedValue(0);
  const textStyles = useAnimatedStyle(() => {
    const differenceBetweenNormalAndCircleWidth =
      buttonTextFullWidth.value -
      /**
       *  Ensure we are matching the animation pace of the button shrink animation.
       *  When using percentage width, the button is targeting zero percent at circle state,
       *  so we also need to target the same to ensure no movement of the text while animating.
       */
      (percentageAvailableWidth === null ? MIN_WIDTH : 0);
    const offset = differenceBetweenNormalAndCircleWidth / 2;

    return {
      left: interpolate(animation.value, [0, 1], [-offset, 0]),
      right: interpolate(animation.value, [0, 1], [-offset, 0]),
      opacity: interpolate(
        animation.value,
        [0.6, 1],
        [0, 1],
        Extrapolation.CLAMP
      ),
    };
  }, [percentageAvailableWidth]);
  const successStyles = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        animation.value,
        [0, 0.6],
        [!loading && success ? 1 : 0, 0],
        Extrapolation.CLAMP
      ),
    };
  }, [loading, success]);
  const loadingStyles = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        animation.value,
        [0, 0.6],
        [loading ? 1 : 0, 0],
        Extrapolation.CLAMP
      ),
    };
  }, [loading]);

  const isDisabled = disabled || loading || success;
  const animatedButtonStyles = useAnimatedStyle(() => {
    return {
      width:
        percentageAvailableWidth !== null
          ? `${interpolate(
              animation.value,
              [0, 1],
              [0, percentageAvailableWidth],
              Extrapolation.CLAMP
            )}%`
          : interpolate(
              animation.value,
              [0, 1],
              [MIN_WIDTH, availableWidth as number],
              Extrapolation.CLAMP
            ),
      borderColor: interpolateColor(
        animation.value,
        [0, 1],
        [
          theme.background,
          theme.button[buttonType].border ??
            theme.button[buttonType].background,
        ]
      ),
    };
  }, [
    percentageAvailableWidth,
    availableWidth,
    buttonType,
    theme.background,
    theme.button,
  ]);
  return (
    <ButtonContainer style={[style, { borderRadius: 20 }]}>
      <AnimatedPressable
        testID={testID ?? `${title} button`}
        disabled={isDisabled}
        onPress={onPressHandler}
        style={[animatedButtonStyles, buttonWrapperStyle]}
        buttonType={buttonType}
        loadingPosition={loadingPosition}
        ref={animatedRef}
      >
        <InvisibleButtonLabelContainer>
          {icon ? (
            <ButtonIcon
              buttonType={buttonType}
              icon={icon}
              container={"COLLAPSED"}
            />
          ) : null}
          <ButtonText
            size="medium"
            $inverted={!disabled}
            buttonType={buttonType}
            numberOfLines={1}
          >
            {title}
          </ButtonText>
        </InvisibleButtonLabelContainer>
        <ContentContainer style={loadingStyles}>
          <WaveIndicator
            color={theme.button[buttonType].text}
            animating={loading}
            interaction={false}
          />
        </ContentContainer>
        <ContentContainer style={successStyles}>
          <StyledIcon
            buttonType={buttonType}
            icon="checkmark"
            size={ICON_SIZE}
          />
        </ContentContainer>
        <ContentContainer style={textStyles}>
          <ButtonLabelContainer>
            {icon ? (
              <ButtonIcon
                buttonType={buttonType}
                icon={icon}
                container={"COLLAPSED"}
              />
            ) : null}
            <ButtonText
              size="medium"
              $inverted={!disabled}
              buttonType={buttonType}
              numberOfLines={1}
            >
              {title}
            </ButtonText>
          </ButtonLabelContainer>
        </ContentContainer>
      </AnimatedPressable>
    </ButtonContainer>
  );
}

const ContentContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const StyledIcon = styled(Icon)<{ buttonType: ButtonType }>`
  color: ${({ theme, buttonType }) => theme.button[buttonType].text};
`;

const AnimatedPressable = styled(
  Animated.createAnimatedComponent(BasePressable)
)<{
  buttonType: ButtonType;
  loadingPosition: "center" | "right";
}>`
  position: relative;
  height: 40px;
  min-width: ${MIN_WIDTH}px;
  justify-content: center;
  align-self: ${({ loadingPosition }) =>
    loadingPosition === "right" ? "flex-end" : "center"};
  background-color: ${({ theme, buttonType }) =>
    theme.button[buttonType].background};
  border-radius: 20px;
  border-width: 1px;
  ${Platform.OS === "web" ? `user-select: none` : ``}
`;

const ButtonLabelContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const ButtonIcon = styled(Icon)<{ buttonType: ButtonType }>`
  color: ${({ theme, buttonType }) => theme.button[buttonType].text};
`;
const ButtonText = styled(Text)<{ buttonType: ButtonType }>`
  color: ${({ theme, buttonType }) => theme.button[buttonType].text};
  ${Platform.OS === "web" ? `text-overflow: ellipsis; user-select: none;` : ``}
`;
const InvisibleButtonLabelContainer = styled(ButtonLabelContainer)<
  Parameters<typeof ButtonLabelContainer>[0]
>`
  ${Platform.OS === "web" ? `visibility: hidden;` : ""}
  opacity: 0;
  padding-left: 16px;
  padding-right: 16px;
`;

const ButtonContainer = styled.View`
  max-width: 100%;
`;

export default Button;
