import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { QuestViewContext } from "../QuestViewContext";
import { useAppSelector } from "@app/store";
import {
  selectQuestInstanceById,
  selectQuestInstanceRoles,
} from "@app/store/cache/questInstances";
import { PromiseTracker } from "@app/util/PromiseTracker";
import isEqual from "react-fast-compare";

export type AuthStrategy = "PUBLIC_QUEST" | "AUTH0";

interface RunQuestViewContextProviderProps extends PropsWithChildren {
  questInstanceId: string;
  allowedAuthStrategies: AuthStrategy[];
}

export const RunQuestViewContextProvider: React.FC<
  RunQuestViewContextProviderProps
> = ({ questInstanceId, allowedAuthStrategies, children }) => {
  const questInstance = useAppSelector((state) => {
    const instance = selectQuestInstanceById(state, questInstanceId);
    if (!instance) {
      return undefined;
    }
    return { status: instance.status, questId: instance.questId };
  }, isEqual);
  const roles = useAppSelector(
    (state) => selectQuestInstanceRoles(state, questInstanceId),
    isEqual
  );
  const publicQuestSessionToken = useAppSelector(
    (state) => state.publicQuestAuth.sessions[questInstanceId] || undefined
  );
  const preSubmitPromiseTracker = useRef(new PromiseTracker()).current;
  const [recentlySubmittedByUser, setRecentlySubmittedByUser] = useState(false);
  const markRecentlySubmittedByUser = useCallback(() => {
    if (!recentlySubmittedByUser) {
      setRecentlySubmittedByUser(true);
    }
  }, [recentlySubmittedByUser]);

  const context = useMemo(() => {
    return {
      templateId: questInstance?.questId,
      viewContext: "RUN",
      questInstanceId,
      recentlySubmittedByUser,
      markRecentlySubmittedByUser,
      preSubmitPromiseTracker,
      status: questInstance?.status,
      roles,
      publicQuestSessionToken: allowedAuthStrategies.includes("PUBLIC_QUEST")
        ? publicQuestSessionToken
        : undefined,
    } as const;
  }, [
    questInstanceId,
    recentlySubmittedByUser,
    markRecentlySubmittedByUser,
    preSubmitPromiseTracker,
    questInstance,
    roles,
    allowedAuthStrategies,
    publicQuestSessionToken,
  ]);
  return (
    <QuestViewContext.Provider value={context}>
      {children}
    </QuestViewContext.Provider>
  );
};
