import { CompletionActionComponentProps } from "@app/components/completionAction";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  CustomItemEventReporter,
  CustomItemV2RunView,
} from "@app/components/item/components/custom/v2/CustomItemV2RunView";
import { CustomItemV2ManageView } from "@app/components/item/components/custom/v2/CustomItemV2ManageView";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";
import {
  CompletionActionRenderData,
  ItemTypesThatCanBeCompletionActions,
} from "@app/types/completionActionRenderData";
import { ItemRenderData } from "@app/types/itemRenderData";
import {
  createPollerForQuestscriptExecution,
  CustomItemDebuggerModal,
  getQuestscriptExecutionStatus,
  ItemRunStatusIndicator,
  Trigger,
} from "@app/components/item/components/custom/CustomItemDebuggerModal";
import { fetchQuestscriptExecutions } from "@app/util/client/requests/questscriptExecutions";
import {
  QuestscriptExecution,
  selectLatestQuestscriptExecution,
} from "@app/store/cache/questscriptExecutions";
import { useAppSelector } from "@app/store";
import { PromisePollerFactory, usePoller } from "@app/util/usePoller";
import promisePoller from "promise-poller";
import {
  ItemDetail,
  QuestscriptExecutionListItem,
  RestrictedQuestscriptExecutionListItem,
} from "@questmate/openapi-spec";
import styled, { useTheme } from "styled-components/native";
import { useRequest } from "@app/util/client/requests";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";
import isEqual from "react-fast-compare";
import { ItemContainer } from "@app/components/item/components/itemContainer";
import { CustomItemV2PreviewView } from "@app/components/item/components/custom";
import { Portal, PortalHost } from "@gorhom/portal";

type CustomItemV2CompletionAction = Pick<
  CompletionActionComponentProps,
  "item" | "readOnly" | "onCompletionActionChange" | "editMode"
>;
export const CustomItemV2CompletionAction: React.FC<
  CustomItemV2CompletionAction
> = (props) => {
  const questContext = useQuestViewContext();

  const [onHeaderPress, setOnHeaderPress] = useState<undefined | (() => void)>(
    undefined
  );

  // Don't show item header if not an admin as it looks weird
  const showItemHeader =
    questContext.viewContext !== "RUN" ||
    (questContext.status === "COMPLETED" &&
      questContext.roles.includes("OWNER"));

  const inlineNodePortalName = `custom-item-inline-node-${props.item.prototype.id}`;
  return (
    <>
      <ContentContainer>
        <ItemContainer
          editMode={props.editMode}
          itemId={props.item.prototype.id}
          name={showItemHeader ? props.item.prototype.name! : ""}
          placeholder={"Action description"}
          onItemNameChange={(newName: string) => {
            props.onCompletionActionChange({
              ...props.item,
              prototype: {
                ...props.item.prototype,
                name: newName,
              },
            });
          }}
          onHeaderPress={onHeaderPress}
          inlineNode={<PortalHost name={inlineNodePortalName} />}
          blockNode={
            questContext.viewContext === "PREVIEW" ? (
              <CustomItemV2PreviewView
                script={props.item.prototype.customScript}
                isCompletionAction={true}
              />
            ) : questContext.viewContext === "MANAGE" ? (
              <CustomItemV2ManageView
                viewMode={props.editMode ? "EDIT" : "READ_ONLY"}
                inlineNodePortalName={inlineNodePortalName}
                onItemChange={(item) =>
                  props.onCompletionActionChange({
                    ...props.item,
                    data: item.data,
                    version: item.version,
                    prototype: {
                      ...props.item.prototype,
                      customScript: (item.prototype as ItemDetail).customScript,
                    },
                  })
                }
                item={convertRewardToItem(props.item)}
              />
            ) : (
              <CustomItemCompletionActionRunView
                item={props.item}
                onCompletionActionChange={props.onCompletionActionChange}
                readOnly={props.readOnly}
                setOnHeaderPress={setOnHeaderPress}
                inlineNodePortalName={inlineNodePortalName}
              />
            )
          }
        />
      </ContentContainer>
    </>
  );
};
const ContentContainer = styled.View`
  margin-left: 20px;
  margin-right: 20px;
`;

interface CustomItemCompletionActionRunViewProps
  extends Pick<
    CustomItemV2CompletionAction,
    "item" | "onCompletionActionChange" | "readOnly"
  > {
  setOnHeaderPress: (onHeaderPress: undefined | (() => void)) => void;
  inlineNodePortalName: string;
}

const CustomItemCompletionActionRunView: React.FC<
  CustomItemCompletionActionRunViewProps
> = ({
  item,
  onCompletionActionChange,
  readOnly,
  setOnHeaderPress,
  inlineNodePortalName,
}) => {
  const questContext = useQuestViewContext(["RUN"]);
  const theme = useTheme();
  const customItemReporter = useRef(new CustomItemEventReporter()).current;
  const trigger = useMemo((): Trigger => {
    return {
      type: "ITEM_INSTANCE",
      identifier: questContext.questInstanceId + "|" + item.prototype.id,
      questInstanceId: questContext.questInstanceId,
      itemId: item.prototype.id,
    };
  }, [questContext, item.prototype.id]);

  const { isValidating: loadingQuestscriptExecutions } = useRequest(
    fetchQuestscriptExecutions(
      trigger.type,
      trigger.identifier,
      questContext.publicQuestSessionToken
    )
  );
  const mostRecentExecution = useAppSelector(
    (state) =>
      selectLatestQuestscriptExecution(state, trigger.type, trigger.identifier),
    isEqual
  );

  const listPoller = usePoller(
    "ItemInstanceQuestExecutions",
    createPollerForFirstCustomItemRunExecution
  );

  const executionPoller = usePoller(
    "QuestExecution",
    createPollerForQuestscriptExecution
  );

  const questIsCompleted =
    isViewContext(questContext, "RUN") && questContext.status === "COMPLETED";
  const noRecentExecutionAvailable = !mostRecentExecution;
  useEffect(() => {
    // start polling for ITEM_RUN executions when the Quest completes
    if (
      questIsCompleted &&
      noRecentExecutionAvailable &&
      !loadingQuestscriptExecutions
    ) {
      const itemInstanceId =
        questContext.questInstanceId + "|" + item.prototype.id;
      listPoller
        .poll(itemInstanceId, questContext.publicQuestSessionToken)
        .catch((e) => {
          console.error(
            "Failed to poll for all Questscript Executions for Item Instance with ID: ",
            itemInstanceId,
            e
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questIsCompleted, loadingQuestscriptExecutions]);

  useEffect(() => {
    if (
      isViewContext(questContext, "RUN") &&
      mostRecentExecution?.status === "PENDING"
    ) {
      executionPoller
        .poll(mostRecentExecution.id, {
          validStatuses: ["OK", "FAILED"],
          publicQuestSessionToken: questContext.publicQuestSessionToken,
        })
        .then(
          (response: {
            status: QuestscriptExecution["status"] | "NOT_YET_PENDING";
          }) => {
            if (["OK", "FAILED"].includes(response.status)) {
              customItemReporter.reportItemRunEventAsCompleted(
                response.status as "OK" | "FAILED"
              );
            }
            return response;
          }
        )
        .catch((e) => {
          console.error(
            "Failed to poll for Questscript Execution with id: ",
            mostRecentExecution.id,
            e
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostRecentExecution]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setOnHeaderPress(() =>
      noRecentExecutionAvailable
        ? undefined
        : () => {
            setShowModal(true);
          }
    );
  }, [noRecentExecutionAvailable, setOnHeaderPress]);

  const showStatusIndicator =
    questContext.status === "COMPLETED" && questContext.roles.includes("OWNER");

  return !questIsCompleted ? null : (
    <>
      {showStatusIndicator ? (
        <Portal hostName={inlineNodePortalName}>
          <ItemRunStatusIndicator
            name={`admin-item-run-${item.prototype.id}`}
            status={
              mostRecentExecution
                ? getQuestscriptExecutionStatus(mostRecentExecution)
                : "PENDING"
            }
          />
        </Portal>
      ) : null}

      <QKLoadingIndicator
        name={`item-run-${item.prototype.id}`}
        loading={
          noRecentExecutionAvailable || mostRecentExecution.status === "PENDING"
        }
        yieldTo={[
          "allCompletionActions",
          `admin-item-run-${item.prototype.id}`,
        ]}
        size={32}
        color={theme.primary}
      />
      <CustomItemV2RunView
        item={convertRewardToItem(item)}
        onItemChange={(updatedItem) =>
          onCompletionActionChange({
            ...item,
            data: updatedItem.data,
            version: updatedItem.version,
          })
        }
        readOnly={readOnly}
        itemRunEventEmitter={customItemReporter}
        inlineNodePortalName={inlineNodePortalName}
      />
      <CustomItemDebuggerModal
        showModal={showModal}
        setShowModal={setShowModal}
        customItemReporter={customItemReporter}
        trigger={trigger}
      />
    </>
  );
};

function convertRewardToItem(
  reward: CompletionActionRenderData
): ItemRenderData<ItemDetail> {
  return {
    data: reward.data,
    version: reward.version,
    validateResult: { valid: true },
    prototype: {
      id: reward.prototype.id,
      type: reward.prototype.type as ItemTypesThatCanBeCompletionActions,
      defaults: reward.prototype.config!,
      position: reward.prototype.position,
      customScript: reward.prototype.customScript || null,
      referenceSlug: "",
      isCompletionAction: true,
      name: "Custom",
      infos: [],
      required: false,
    },
  };
}

export const createPollerForFirstCustomItemRunExecution: PromisePollerFactory<
  string | undefined,
  QuestscriptExecutionListItem | RestrictedQuestscriptExecutionListItem
> = (entityId, publicQuestSessionToken) =>
  promisePoller({
    taskFn: () => {
      return fetchQuestscriptExecutions(
        "ITEM_INSTANCE",
        entityId,
        publicQuestSessionToken
      )
        .execute()
        .then((executions) => {
          return executions[0];
        });
    },
    shouldContinue(
      _error: unknown,
      execution:
        | QuestscriptExecutionListItem
        | RestrictedQuestscriptExecutionListItem
    ): boolean {
      return !execution?.id;
    },
    timeout: 5 * 1000,
    retries: 100,
    strategy: "linear-backoff",
    start: 250,
    increment: 250,
    masterTimeout: 3 * 60 * 1000,
  });
