import QKModal, { ModalDisplayMode } from "@app/components/modal";
import React, { useState, useEffect, useRef } from "react";
import Button from "@app/components/questkit/button";
import styled, { useTheme } from "styled-components/native";
import { TabBar, TabBarItem } from "@app/components/questkit/TabBar";
import { CodeEditor } from "@app/components/item/components/custom/edit/CodeEditor";
import { QuestscriptExecutionListItem } from "@questmate/openapi-spec";
import { Platform } from "react-native";
import { Text } from "@app/components/questkit/text";
import { usePromise } from "@app/util/usePromise";
import { useAppSelector } from "@app/store";
import {
  selectQuestscriptExecutionsByTrigger,
  QuestscriptExecution,
} from "@app/store/cache/questscriptExecutions";
import {
  queueItemRunEvent,
  fetchQuestscriptExecution,
} from "@app/util/client/requests/questscriptExecutions";
import promisePoller from "promise-poller";
import { isBefore, subMinutes } from "date-fns";
import QKScrollView, {
  QKScrollViewController,
} from "@app/components/questkit/ScrollView";
import { usePoller, PromisePollerFactory } from "@app/util/usePoller";
import { CustomItemEventReporter } from "@app/components/item/components/custom/v2/CustomItemV2RunView";
import QKIcon from "@app/components/questkit/icon";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";
import isEqual from "react-fast-compare";

interface ITrigger {
  type: QuestscriptExecutionListItem["triggerType"];
  identifier: QuestscriptExecutionListItem["triggerIdentifier"];
}

interface ItemInstanceTrigger extends ITrigger {
  type: "ITEM_INSTANCE";
  identifier: string;
  questInstanceId: string;
  itemId: string;
}

export type Trigger = ItemInstanceTrigger;

type CustomItemExecutionsViewerProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  trigger: Trigger;
  customItemReporter: CustomItemEventReporter;
};

export const CustomItemDebuggerModal: React.FC<
  CustomItemExecutionsViewerProps
> = ({ showModal, setShowModal, trigger, customItemReporter }) => {
  const executions = useAppSelector(
    (state) =>
      selectQuestscriptExecutionsByTrigger(
        state,
        trigger.type,
        trigger.questInstanceId + "|" + trigger.itemId
      ),
    isEqual
  );

  const poller = usePoller(
    "QuestExecution",
    createPollerForQuestscriptExecution
  );

  useEffect(() => {
    if (showModal) {
      executions
        .filter(({ status }) => status === "PENDING")
        .forEach((execution) => {
          const args = {
            validStatuses: ["OK", "FAILED"] as QuestscriptExecution["status"][],
          };
          if (poller.isPolling(execution.id, args)) {
            return;
          }

          poller
            .poll(execution.id, args)
            .then(
              (response: {
                status: QuestscriptExecution["status"] | "NOT_YET_PENDING";
              }) => {
                if (["OK", "FAILED"].includes(response.status)) {
                  customItemReporter.reportItemRunEventAsCompleted(
                    response.status as "OK" | "FAILED"
                  );
                }
                return response;
              }
            )
            .catch((e) =>
              console.error(
                "Unable to poll for questscript execution data successfully.",
                e
              )
            );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, executions]);

  const [selectedExecutionId, setSelectedExecutionId] = useState<string>();

  useEffect(() => {
    if (!selectedExecutionId && executions.length > 0) {
      setSelectedExecutionId(executions[0].id);
    }
  }, [executions, selectedExecutionId]);

  const hasPendingExecutions = executions.some(
    (execution) => getQuestscriptExecutionStatus(execution) === "PENDING"
  );

  const executionsListControllerRef = useRef<QKScrollViewController>(null);

  return (
    <QKModal
      showModal={showModal}
      setShowModal={setShowModal}
      title={"Custom Item Debugger"}
      displayMode={ModalDisplayMode.FULL_SCREEN}
    >
      <ModalContainer>
        <MainContent>
          <EditorWrapper>
            <LogViewerHeader>
              <Text>
                Run{" "}
                {executions.findIndex(({ id }) => selectedExecutionId === id) +
                  1}
              </Text>
            </LogViewerHeader>
            <CodeWrapper show={true}>
              <CodeEditor
                value={formatExecution(
                  executions.find(({ id }) => id === selectedExecutionId)
                )}
                readOnly={true}
                autoFocus={false}
              />
            </CodeWrapper>
          </EditorWrapper>
          {Platform.OS === "web" ? (
            <SideContentWrapper>
              <TabBar>
                <TabBarItem
                  title={`Item Runs`}
                  active={true}
                  onPress={() => undefined}
                />
              </TabBar>

              <QueueItemRunButton
                onItemRunStarted={(executionId: string) => {
                  executionsListControllerRef.current?.scrollToStart();
                  setSelectedExecutionId(executionId);
                }}
                hasPendingExecutions={hasPendingExecutions}
                questInstanceId={trigger.questInstanceId}
                itemId={trigger.itemId}
              />
              <QKScrollView ref={executionsListControllerRef}>
                {executions
                  .map((execution, index) => (
                    <React.Fragment key={execution.id}>
                      <ExecutionCard
                        execution={execution}
                        attemptNumber={index + 1}
                        onSelect={() => setSelectedExecutionId(execution.id)}
                      />
                      {index === 0 ? null : <SpacerBetweenExecutionCards />}
                    </React.Fragment>
                  ))
                  .reverse()}
              </QKScrollView>
            </SideContentWrapper>
          ) : null}
        </MainContent>
        <DoneButton title={"Done"} onPress={() => setShowModal(false)} />
      </ModalContainer>
    </QKModal>
  );
};

interface QueueItemRunButtonProps {
  questInstanceId: string;
  itemId: string;
  hasPendingExecutions: boolean;
  onItemRunStarted?: (executionId: string) => void;
}

const QueueItemRunButton: React.FC<QueueItemRunButtonProps> = ({
  questInstanceId,
  itemId,
  hasPendingExecutions,
  onItemRunStarted,
}) => {
  const { execute: queueItemRun, isLoading: waitingForItemRunToBeQueued } =
    usePromise(async () => {
      const { executionId } = await queueItemRunEvent(questInstanceId, itemId);

      const pollUntilStarted = createPollerForQuestscriptExecution(
        executionId,
        {
          validStatuses: ["PENDING", "OK", "FAILED"],
        }
      );

      return pollUntilStarted
        .then(() => {
          onItemRunStarted?.(executionId);
        })
        .catch((e) => {
          console.error(
            "Unable to poll for questscript execution data successfully.",
            e
          );
        });
    });

  return (
    <StyledRunButton
      title="Run Again"
      onPress={queueItemRun}
      loading={waitingForItemRunToBeQueued}
      success={hasPendingExecutions}
    />
  );
};

type ExecutionCardProps = {
  execution: QuestscriptExecution;
  attemptNumber: number;
  onSelect: () => void;
};

export function getQuestscriptExecutionStatus(
  execution: Pick<QuestscriptExecution, "status" | "startedAt">
) {
  return execution.status !== "PENDING"
    ? execution.status
    : isBefore(new Date(execution.startedAt), subMinutes(new Date(), 2))
    ? "TIMED_OUT"
    : "PENDING";
}

const ExecutionCard: React.FC<ExecutionCardProps> = ({
  execution,
  attemptNumber,
  onSelect,
}) => {
  const status = getQuestscriptExecutionStatus(execution);

  return (
    <ExecutionCardContainer onPress={onSelect}>
      <Row>
        <StyledText size={"mediumBold"}>Run {attemptNumber}</StyledText>
        <ItemRunStatusIndicator
          name={`questscript-execution-${execution.id}`}
          status={status}
        />
      </Row>
      <Row>
        <Text>Started</Text>
        <Text>{new Date(execution.startedAt).toLocaleString()}</Text>
      </Row>
      <Row noBottomMargin>
        <Text>Completed</Text>
        <Text>
          {execution.completedAt
            ? new Date(execution.completedAt).toLocaleString()
            : ""}
        </Text>
      </Row>
    </ExecutionCardContainer>
  );
};

const ExecutionCardContainer = styled.Pressable`
  padding: 20px;
  border-radius: 20px;
  min-height: 60px;
  background-color: ${({ theme }) => theme.cardInCard};
  cursor: pointer;
`;
const Row = styled.View<{ noBottomMargin?: true }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ noBottomMargin }) => (noBottomMargin ? "" : "margin-bottom: 12px")};
`;
const StyledText = styled(Text)`
  margin-right: 12px;
`;

const StyledRunButton = styled(Button)`
  margin-bottom: 20px;
`;

const LogViewerHeader = styled.View`
  height: 50px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.cardInCard};
`;

const ModalContainer = styled.View`
  padding: 20px;
  height: 100%;
`;

const MainContent = styled.View`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 20px;
`;

const EditorWrapper = styled.View`
  display: flex;
  height: 100%;
  width: ${() => (Platform.OS === "web" ? "60%" : "100%")};
`;

const CodeWrapper = styled.View<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  padding-bottom: 50px;
  height: 100%;
`;

const DoneButton = styled(Button)`
  flex-grow: 0;
`;

const SpacerBetweenExecutionCards = styled.View`
  height: 20px;
`;

const SideContentWrapper = styled.View`
  display: flex;
  padding-horizontal: 40px;
  width: 40%;
`;

function formatExecution(execution?: QuestscriptExecution) {
  if (!execution) {
    return "No executions yet.";
  }

  return JSON.stringify(execution, null, 2);
}

export const ItemRunStatusIndicator: React.FC<{
  status: QuestscriptExecution["status"] | "TIMED_OUT" | undefined;
  name: string;
  yieldTo?: string[];
}> = ({ status, name, yieldTo }) => {
  const theme = useTheme();

  return status === "OK" ? (
    <QKIcon name="checkmark" />
  ) : status === "PENDING" ? (
    <StyledQKLoadingIndicator
      name={name}
      yieldTo={yieldTo}
      color={theme.primary}
    />
  ) : status === "FAILED" ? (
    <QKIcon name="skull" themeType="warning" />
  ) : status === "TIMED_OUT" ? (
    <QKIcon name="hourglass" themeType="warning" />
  ) : null;
};

const StyledQKLoadingIndicator = styled(QKLoadingIndicator)`
  margin: 4px;
`;

export const createPollerForQuestscriptExecution: PromisePollerFactory<
  {
    validStatuses: QuestscriptExecution["status"][];
    publicQuestSessionToken?: string | undefined;
  },
  { status: QuestscriptExecution["status"] | "NOT_YET_PENDING" }
> = (entityId: string, { validStatuses, publicQuestSessionToken }) =>
  promisePoller({
    taskFn: () =>
      fetchQuestscriptExecution(entityId, publicQuestSessionToken).catch(
        (error) => {
          // continue trying to poll if the execution has not started yet, but stop if other errors occur
          if (error.status === 404) {
            return { status: "NOT_YET_PENDING" };
          } else {
            throw error;
          }
        }
      ),
    shouldContinue(
      _error: unknown,
      questscriptExecution?: QuestscriptExecutionListItem
    ): boolean {
      return (
        !questscriptExecution ||
        !validStatuses.includes(questscriptExecution.status)
      );
    },
    timeout: 5 * 1000,
    retries: 100,
    strategy: "linear-backoff",
    start: 250,
    increment: 250,
    masterTimeout: 3 * 60 * 1000,
  });
