import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";
import {
  CombinedRoutesParamList,
  useAppNavigation,
  useAppRoute,
} from "@app/navigation/QMNavigator";
import { useAppDispatch, useAppSelector } from "@app/store";
import { useDelayedAction } from "@app/components/NavigationButton";
import { usePromise } from "@app/util/usePromise";
import {
  getAuth0Connection,
  linkAuth0Accounts,
  login,
  LoginToQuestmateParams,
} from "@app/util/client/requests/login";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import {
  IdentifierParseResult,
  NullIdentifier,
  parseAsEmailOrPhone,
} from "@app/screens/login/IdentifierParser";
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  TouchableWithoutFeedback,
} from "react-native";
import { Analytics } from "@app/analytics";
import * as Auth0 from "@app/util/Auth0";
import linkingConfig, {
  getPathForScreen,
  getRouteFromPath,
} from "@app/navigation/linkingConfig";
import { setPostLoginTargetUrl } from "@app/store/UI";
import { sentry } from "@app/util/sentry";
import { userLogin } from "@app/store/auth";
import { Boundary } from "@app/components/screen/boundary";
import { ImageLogo } from "@app/components/images";
import Button from "@app/components/questkit/button";
import Text from "@app/components/questkit/text";
import { LinkText } from "@app/components/LinkText";
import { selectQuestById } from "@app/store/cache/quests";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import styled from "styled-components/native";
import EmailPhoneInput from "@app/components/questkit/EmailPhoneInput";
import TextInput from "@app/components/questkit/textInput";
import { FadeInView } from "@app/components/animated/fadeInView";
import { useAuth0Context } from "@app/authentication/Auth0Context";
import { useIsFocused } from "@react-navigation/native";
import { useFocusableRef, useFocusController } from "@app/util/focus";
import { storeRefreshToken } from "@app/store/persistConfiguration";
import QKScrollView from "@app/components/questkit/ScrollView";
import { ENV } from "@app/config/env";
import { selectAllQuestStartTriggers } from "@app/store/cache/questStartTriggers";
import { clearSWRCache } from "@app/screens/LogoutScreen";

const termsURL = "https://www.questmate.com/terms";
const privacyURL = "https://www.questmate.com/privacy";

export const LoginScreen: React.FC = () => {
  const {
    openAuth0UniversalLogin: _openAuth0UniversalLogin,
    isAuthenticationResultAvailable,
    getUniversalLoginResult,
  } = useAuth0Context();
  const navigation = useAppNavigation();
  const route = useAppRoute<"Login">();
  const [isLoadingUniversalLoginResult, setIsLoadingUniversalLoginResult] =
    useState(isAuthenticationResultAvailable);
  const [isWaitingForUniversalLogin, setIsWaitingForUniversalLogin] =
    useState(false);
  const openAuth0UniversalLogin = useCallback(
    (...args: Parameters<typeof _openAuth0UniversalLogin>) => {
      setIsWaitingForUniversalLogin(true);
      _openAuth0UniversalLogin(...args);
    },
    [_openAuth0UniversalLogin]
  );

  useSyncedTargetUrl();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticationResultAvailable) {
      setIsWaitingForUniversalLogin(false);
      void getUniversalLoginResult().then(async (authenticationResult) => {
        if (authenticationResult.success) {
          const identifier = authenticationResult.savedAppState?.identifier;
          if (identifier) {
            setRawIdentifier(identifier);
          }
          if (authenticationResult.savedAppState?.priorLogin?.accessToken) {
            // If there is an existing accountLinkingState then we are in the middle of linking an account
            // TODO: for non-SAML linking we may want the API to tell us which one should be primary (if it matters...)
            void linkAuth0Accounts(
              authenticationResult.accessToken,
              authenticationResult.savedAppState?.priorLogin?.accessToken
            ).then((linkResult) => {
              setIsLoadingUniversalLoginResult(false);
              if (linkResult.success) {
                void loginToQuestmate({
                  identifier,
                  accessToken: authenticationResult.accessToken,
                  refreshToken: authenticationResult.refreshToken,
                  postLoginTargetUrl: authenticationResult.savedAppState?.url,
                  debugData: {
                    loginMechanism: "universal login",
                    identifier: {
                      type: "UNKNOWN",
                      email: authenticationResult.userAttributes?.email,
                    },
                    message:
                      "This attempt was made right after successfully linking an account.",
                  },
                });
              } else {
                if (linkResult.errorCode === "EMAILS_MUST_MATCH") {
                  setErrorMessage(
                    `The email address you used to log in does not match the email address associated with your account. Please try again.`
                  );
                } else {
                  setErrorMessage(
                    `Oops, an unexpected error occurred when linking your account. Please try again.`
                  );
                }
                setOtpCode("");
                setRawIdentifier("");
                setLoginStep("1_PROVIDE_IDENTIFIER");
              }
            });
          } else {
            setIsLoadingUniversalLoginResult(false);
            void loginToQuestmate({
              identifier,
              accessToken: authenticationResult.accessToken,
              refreshToken: authenticationResult.refreshToken,
              postLoginTargetUrl: authenticationResult.savedAppState?.url,
              debugData: {
                loginMechanism: "universal login",
                identifier: {
                  type: "UNKNOWN",
                  email: authenticationResult.userAttributes?.email,
                },
              },
            });
          }
        } else {
          setIsLoadingUniversalLoginResult(false);
          console.error(
            "Error authenticating with universal login",
            authenticationResult
          );
          sentry.captureException(authenticationResult.error);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticationResultAvailable]);

  const [loginStep, setLoginStep] = useState<
    "1_PROVIDE_IDENTIFIER" | "2_ENTER_OTP"
  >("1_PROVIDE_IDENTIFIER");
  const [errorMessage, setErrorMessage] = useState("");

  const [rawIdentifier, setRawIdentifier] = useState("");
  const [parsedIdentifier, setParsedIdentifier, parsedIdentifierRef] =
    useStateWithRef<IdentifierParseResult>(NullIdentifier);

  const [otpCode, setOtpCode, otpCodeRef] = useStateWithRef("");

  const [waitTime, setWaitTime] = useState(0);
  const resendCodeString = `Resend Code${waitTime ? ` (${waitTime})` : ""}`;

  const identifierInput = useFocusableRef("LOGIN/IDENTIFIER_INPUT");
  const codeInput = useFocusableRef("LOGIN/OTP_INPUT");
  const focusController = useFocusController();
  useEffectOnce(() => focusController.focus("LOGIN/IDENTIFIER_INPUT"));

  const { execute: initiateLogin, isLoading: isInitiatingLogin } = usePromise(
    async () => {
      setErrorMessage("");

      const identifier = parsedIdentifierRef.current;
      if (!identifier.isValid) {
        throw new Error("Unexpected Error: Invalid identifier");
      }
      Analytics.trackEvent("Continue Login Button Pressed", {
        identifier: identifier.inputValueMasked,
      });
      let connection = route.params?.forceConnection;
      if (!connection) {
        const result = await getAuth0Connection(identifier);
        if (!result.success || !result.data?.connection) {
          throw new Error("Unexpected Error: Failed to get connection");
        }
        connection = result.data.connection;
      }

      if (["sms", "email"].includes(connection)) {
        void requestOTP();
      } else {
        openAuth0UniversalLogin({
          appState: { url: route.params?.url, identifier: identifier.value },
          identifierHint: identifier.value,
          connection,
        });
      }
    }
  );

  const { execute: requestOTP, isLoading: isSendingOTP } = usePromise(
    async () => {
      setErrorMessage("");

      const identifier = parsedIdentifierRef.current;
      if (!identifier.isValid) {
        throw new Error("Unexpected Error: Invalid identifier");
      }

      const result = await Auth0.sendOneTimePassword(
        identifier.type === "PHONE_NUMBER" ? "sms" : "email",
        identifier.value
      );
      Analytics.trackEvent("Request Login Code", {
        success: result.success,
        identifier: identifier.inputValueMasked,
        identifierType: identifier.type === "PHONE_NUMBER" ? "Mobile" : "Email",
        receivedSignupsDisabledError:
          !result.success && result.errorCode === "PUBLIC_SIGNUP_DISABLED",
      });

      if (result.success) {
        setLoginStep("2_ENTER_OTP");
        setWaitTime(10);
      } else {
        if (result.errorCode === "PUBLIC_SIGNUP_DISABLED") {
          setErrorMessage(
            `Signing up via ${
              identifier.type === "PHONE_NUMBER"
                ? "mobile phone number"
                : "email"
            } is not available at this time. Consider signing up via ${
              identifier.type === "PHONE_NUMBER"
                ? "email"
                : "mobile phone number"
            } or try again later.`
          );
        } else {
          setErrorMessage(
            `Uhm, this Email or Mobile Number looks a bit fishy... 🐟`
          );
        }
      }
    }
  );

  const { execute: loginToQuestmate, isLoading: isLoggingIn } = usePromise(
    async (
      params: LoginToQuestmateParams & {
        refreshToken: string;
        identifier?: string;
      }
    ) => {
      const loginResult = await login(params);

      if (loginResult.success) {
        if (loginResult.data.requiredActions.length > 0) {
          const firstAction = loginResult.data.requiredActions[0];
          switch (firstAction.type) {
            case "NAVIGATE": {
              let newPostLoginTargetUrl: undefined | string;

              if (typeof firstAction.data.to === "string") {
                const pathIsValid = !!linkingConfig.getStateFromPath(
                  firstAction.data.to,
                  { pathConfigurationOverride: "LOGGED_IN" }
                );
                if (pathIsValid) {
                  newPostLoginTargetUrl = firstAction.data.to;
                }
              } else {
                newPostLoginTargetUrl = getPathForScreen(firstAction.data.to, {
                  pathConfigurationOverride: "LOGGED_IN",
                });
              }

              if (newPostLoginTargetUrl) {
                dispatch(setPostLoginTargetUrl(newPostLoginTargetUrl));
              }
              break;
            }
            case "LINK_ACCOUNT": {
              setLoginStep("1_PROVIDE_IDENTIFIER");
              openAuth0UniversalLogin({
                appState: {
                  url: params.postLoginTargetUrl,
                  identifier: params.identifier,
                  priorLogin: {
                    accessToken: params.accessToken,
                    refreshToken: params.refreshToken,
                  },
                },
                identifierHint: firstAction.data.identifier,
                connection: firstAction.data.connection,
              });
              break;
            }
            default:
              console.warn(
                "Unknown required action type",
                (firstAction as { type?: string })?.type
              );
              sentry.captureMessage(
                "Unknown required action type received on log in.",
                {
                  extra: {
                    requiredActions: loginResult.data.requiredActions,
                  },
                }
              );
          }
        } else if (params.postLoginTargetUrl) {
          dispatch(setPostLoginTargetUrl(params.postLoginTargetUrl));
        }

        // Login is successful if we are provided a database user
        if (loginResult.data.user) {
          cancelAutomaticNavigationToKioskQuest();
          await clearSWRCache();
          await storeRefreshToken(params.refreshToken);
          dispatch(
            userLogin({
              user: loginResult.data.user,
              accessToken: params.accessToken,
            })
          );
        }
      } else {
        setErrorMessage("Uh oh, something went wrong. Please try again later.");
        setOtpCode("");
        setRawIdentifier("");
        setLoginStep("1_PROVIDE_IDENTIFIER");
        focusController.focus("LOGIN/IDENTIFIER_INPUT");
      }
    }
  );

  const { execute: authenticateUser, isLoading: isAuthenticatingUser } =
    usePromise(async () => {
      setErrorMessage("");

      const identifier = parsedIdentifierRef.current;
      if (!identifier.isValid) {
        throw new Error("Unexpected Error: Invalid identifier");
      }

      const authenticationResult = await Auth0.authenticateUserUsingOTP(
        identifier.type === "PHONE_NUMBER" ? "sms" : "email",
        identifier.value,
        otpCodeRef.current
      );
      Analytics.trackEvent("Submit Login Code", {
        success: authenticationResult.success,
      });

      if (authenticationResult.success) {
        void loginToQuestmate({
          accessToken: authenticationResult.accessToken,
          refreshToken:
            authenticationResult.refreshToken ||
            "noRefreshTokenFromOTPAuthentication",
          postLoginTargetUrl: route.params?.url,
          debugData: { loginMechanism: "built-in OTP", identifier },
        });
      } else {
        // Set error message
        if (authenticationResult.errorCode === "VERIFICATION_CODE_EXPIRED") {
          setErrorMessage("Verification code expired.");
        } else if (
          authenticationResult.errorCode === "INCORRECT_VERIFICATION_CODE"
        ) {
          setErrorMessage("That code doesn't look correct.");
        } else {
          setErrorMessage("Hmm, somethings not right. Please try again.");
        }

        // Reset code if error received
        setOtpCode("");
        focusController.focus("LOGIN/OTP_INPUT");
      }
    });
  const isScreenFocused = useIsFocused();

  const kioskQuestPublicId = useAppSelector(
    (state) => state.ui.kioskQuestPublicId
  );
  const [hideKioskNavigationPrompt, setHideKioskNavigationPrompt] = useState(
    isLoadingUniversalLoginResult
  );
  const navigateToKioskQuest = useCallback(() => {
    if (kioskQuestPublicId) {
      navigation.navigate("PublicAssignment", { id: kioskQuestPublicId });
    }
  }, [kioskQuestPublicId, navigation]);

  const {
    remainingDelaySeconds,
    cancel: cancelAutomaticNavigationToKioskQuest,
  } = useDelayedAction({
    action: navigateToKioskQuest,
    delayMs: 10 * 1000,
    disabled:
      !kioskQuestPublicId || hideKioskNavigationPrompt || !isScreenFocused,
  });

  const onPressNavigateToKioskQuest = useCallback(() => {
    cancelAutomaticNavigationToKioskQuest();
    navigateToKioskQuest();
  }, [cancelAutomaticNavigationToKioskQuest, navigateToKioskQuest]);

  const loginId = route.params?.loginId;
  const loginIdHasBeenUsedRef = useRef(false);
  useEffect(() => {
    // Prefills mobile/email input with parameter provided via ?loginId=xxx
    const handleLoginAs = async () => {
      let identifier = loginId;
      if (!identifier) {
        const initialUrl = await linkingConfig.getInitialURL();
        if (initialUrl) {
          identifier =
            new URL(initialUrl).searchParams.get("loginId") || undefined;
        }
      }

      if (identifier) {
        const result = parseAsEmailOrPhone(identifier);
        // if valid and user has not yet entered anything
        if (
          result.isValid &&
          parsedIdentifierRef.current.inputValue.length === 0 &&
          !loginIdHasBeenUsedRef.current
        ) {
          loginIdHasBeenUsedRef.current = true;
          // Set actual field
          setRawIdentifier(identifier);
          setParsedIdentifier(result);
          void initiateLogin();
        }
      }
    };
    // Avoid doing this twice if multiple login routes are mounted.
    // That can happen if the user is viewing a Quest or Template Preview and is then redirected to be logged in.
    if (isScreenFocused) {
      void handleLoginAs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginId]);

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setWaitTime((waitTime) => {
        return waitTime > 0 ? waitTime - 1 : 0;
      });
    }, 1000);
    return () => {
      clearInterval(counterInterval);
    };
  }, []);

  useEffect(() => {
    // Auto-enter when OTP input length is reached
    if (otpCode.length === 6) {
      void authenticateUser();
    }
  }, [authenticateUser, otpCode]);

  const onPress = useCallback(() => {
    if (loginStep === "2_ENTER_OTP") {
      void authenticateUser();
    } else if (parsedIdentifierRef.current.isValid) {
      void initiateLogin();
    }
  }, [authenticateUser, loginStep, parsedIdentifierRef, initiateLogin]);

  const onChangeIdentifier = useCallback(
    (identifier: IdentifierParseResult) => {
      if (identifier.inputValue.length > 0) {
        const selectedCountry =
          identifier.type === "PHONE_NUMBER" && identifier.detectedCountry
            ? {
                countryNameEn: identifier.detectedCountry.countryNameEn,
                countryCallingCode:
                  identifier.detectedCountry.countryCallingCode,
              }
            : undefined;

        Analytics.trackEventDebounced(
          "loginScreenIdentifierInput",
          "Set Login Identifier",
          {
            selectedCountry,
            inputType: identifier.type === "PHONE_NUMBER" ? "phone" : "email",
            // mask their personal data
            value:
              identifier.type === "UNKNOWN"
                ? identifier.inputValue
                : identifier.inputValueMasked,
          }
        );
      }
      setParsedIdentifier(identifier);
    },
    [setParsedIdentifier]
  );
  return (
    <QKScrollView
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: 16,
      }}
    >
      <Boundary>
        <StyledFadeInView>
          <IconWrapper>
            <ImageLogo style={{ width: 213, height: 40 }} />
          </IconWrapper>
          {kioskQuestPublicId &&
          !isLoadingUniversalLoginResult &&
          !hideKioskNavigationPrompt ? (
            <KioskModeContainer>
              <Button
                title={`Resume Kiosk Mode (${remainingDelaySeconds})`}
                onPress={onPressNavigateToKioskQuest}
              />
              <PressableLink onPress={() => setHideKioskNavigationPrompt(true)}>
                <Text $underlined>Exit Kiosk Mode</Text>
              </PressableLink>
            </KioskModeContainer>
          ) : (
            <>
              {loginStep === "2_ENTER_OTP" ? (
                <StyledText>
                  We&apos;ve sent a confirmation code to{"\n"}
                  <Text size="mediumBold">{parsedIdentifier.format()}</Text>
                  {"\n\nPlease also check your spam folder."}
                </StyledText>
              ) : (
                <ScreenSpecificPrompt redirectUrl={route.params?.url} />
              )}

              {errorMessage !== "" && (
                <StyledText $warning>{errorMessage}</StyledText>
              )}
              <InputWrapper>
                {loginStep === "1_PROVIDE_IDENTIFIER" ? (
                  <StyledEmailPhoneInput
                    key={`mobileOrEmail`}
                    {...(isLoadingUniversalLoginResult
                      ? { placeholder: "..." }
                      : {})}
                    ref={identifierInput}
                    value={rawIdentifier}
                    onChangeText={setRawIdentifier}
                    onChangeIdentifier={onChangeIdentifier}
                    editable={
                      !isLoadingUniversalLoginResult &&
                      !isInitiatingLogin &&
                      !isSendingOTP &&
                      !isLoggingIn
                    }
                    onSubmitEditing={onPress}
                    autoFocus={true}
                  />
                ) : (
                  <StyledTextInput
                    key="code"
                    value={otpCode}
                    onChangeText={(text: string) => {
                      // only accept numbers
                      const cleanedCode = text.replaceAll(/\D/g, "");
                      setOtpCode(cleanedCode);
                      return cleanedCode;
                    }}
                    placeholder={"Enter code"}
                    textContentType="oneTimeCode"
                    editable={!isAuthenticatingUser && !isLoggingIn}
                    maxLength={6}
                    inputMode="numeric"
                    onSubmitEditing={onPress}
                    autoFocus={true}
                    ref={codeInput}
                    textAlign="center"
                  />
                )}

                <StyledButton
                  title={
                    loginStep === "1_PROVIDE_IDENTIFIER"
                      ? "Continue"
                      : "Confirm"
                  }
                  loading={
                    isWaitingForUniversalLogin ||
                    isLoadingUniversalLoginResult ||
                    isLoggingIn ||
                    isAuthenticatingUser ||
                    isSendingOTP ||
                    isInitiatingLogin
                  }
                  onPress={onPress}
                  disabled={
                    isLoggingIn ||
                    isWaitingForUniversalLogin ||
                    (loginStep === "1_PROVIDE_IDENTIFIER" &&
                      (isLoadingUniversalLoginResult ||
                        isInitiatingLogin ||
                        isSendingOTP ||
                        !parsedIdentifier.isValid)) ||
                    (loginStep === "2_ENTER_OTP" &&
                      (isAuthenticatingUser || otpCode.length !== 6))
                  }
                />
                {kioskQuestPublicId && loginStep === "1_PROVIDE_IDENTIFIER" ? (
                  <PressableLink onPress={navigateToKioskQuest}>
                    <CenteredText $underlined>Resume Kiosk Mode</CenteredText>
                  </PressableLink>
                ) : null}
              </InputWrapper>
              {loginStep === "2_ENTER_OTP" ? (
                <>
                  <LinkedText
                    disabled={waitTime > 0}
                    onPress={() => {
                      if (waitTime < 1) {
                        Analytics.trackEvent(
                          "Request Another Login Code Button Pressed"
                        );
                        void requestOTP();
                        setWaitTime(10);
                      }
                    }}
                  >
                    {resendCodeString}
                  </LinkedText>

                  <LinkedText
                    onPress={() => {
                      if (!isAuthenticatingUser) {
                        setLoginStep("1_PROVIDE_IDENTIFIER");
                        setRawIdentifier("");
                        setOtpCode("");
                      }
                    }}
                  >
                    Use different Phone Number or Email
                  </LinkedText>
                  <LinkText
                    to={"mailto:hello@questmate.com?subject=Login issues 😢"}
                    newTabOnWeb={true}
                    textStyle={{ size: "small" }}
                  >
                    Reach out for help! 👩‍🚒🚨
                  </LinkText>
                  <SmallSpacer />
                  <LinkText
                    textStyle={{ size: "small" }}
                    to={"https://www.questmate.com"}
                    newTabOnWeb={true}
                  >
                    What is Questmate?
                  </LinkText>
                </>
              ) : (
                <>
                  {!!errorMessage && (
                    <LinkText
                      to={"mailto:hello@questmate.com?subject=Login issues 😢"}
                      newTabOnWeb={true}
                      textStyle={{ size: "small" }}
                    >
                      Reach out for help! 👩‍🚒🚨
                    </LinkText>
                  )}
                  <Text size="small" style={{ marginBottom: 6 }}>
                    By continuing, you agree to our
                  </Text>
                  <PoliciesView style={{ marginBottom: 20 }}>
                    <LinkText
                      textStyle={{ size: "small" }}
                      to={termsURL}
                      newTabOnWeb={true}
                    >
                      Terms
                    </LinkText>
                    <Text size="small">{" & "}</Text>

                    <LinkText
                      textStyle={{ size: "small" }}
                      to={privacyURL}
                      newTabOnWeb={true}
                    >
                      Privacy Policy
                    </LinkText>
                    <Text size="small">.</Text>
                  </PoliciesView>
                  {parsedIdentifier.type === "PHONE_NUMBER" &&
                  parsedIdentifier.isValid ? (
                    <SMSConsent>
                      <CenteredText size="small">
                        {" "}
                        Additionally, you agree to receive SMS messages from
                        Questmate for the purposes of logging in and receiving
                        optional app notifications.
                      </CenteredText>
                      <CenteredText size="small" style={{ marginTop: 10 }}>
                        Message and data rates may apply.
                      </CenteredText>
                    </SMSConsent>
                  ) : null}
                  <LinkText
                    textStyle={{ size: "small" }}
                    to={"https://www.questmate.com"}
                    newTabOnWeb={true}
                  >
                    What is Questmate?
                  </LinkText>
                </>
              )}
            </>
          )}
        </StyledFadeInView>
      </Boundary>
    </QKScrollView>
  );
};

type ScreenSpecificPromptProps = {
  redirectUrl?: string;
};
const ScreenSpecificPrompt: React.FC<ScreenSpecificPromptProps> = ({
  redirectUrl,
}) => {
  const redirectScreen = useMemo(
    () => getRouteFromPath(redirectUrl),
    [redirectUrl]
  );

  const helperText = useAppSelector((state) => {
    if (redirectScreen?.name === "PublicAssignment") {
      const publicId = redirectScreen.params?.id;
      if (publicId) {
        const matchingStartTrigger = selectAllQuestStartTriggers(state).find(
          (trigger) => trigger.config?.publicId === publicId
        );
        if (matchingStartTrigger?.questPrototypeId) {
          const questName = selectQuestPrototypeById(
            state,
            matchingStartTrigger.questPrototypeId
          )?.name;
          if (questName) {
            return (
              <>
                Provide an email to continue to{" "}
                <Text size={"mediumBold"}>{questName}</Text>.
              </>
            );
          }
        }
      }
      return <>Provide an email to access this Quest.</>;
    } else if (
      redirectScreen?.name === "TemplateCreate" ||
      redirectScreen?.name === "PublicTemplatePreview"
    ) {
      let templateId;
      let questPrototypeId;

      if (redirectScreen?.name === "TemplateCreate") {
        templateId = redirectScreen.params?.sourceTemplateId;
      } else if (redirectScreen?.name === "PublicTemplatePreview") {
        questPrototypeId = redirectScreen.params?.questPrototypeId;
      }

      if (templateId) {
        const quest = selectQuestById(state, templateId);
        if (quest?.currentQuestPrototypeId) {
          questPrototypeId = quest.currentQuestPrototypeId;
        }
      }

      if (questPrototypeId) {
        const questPrototype = selectQuestPrototypeById(
          state,
          questPrototypeId
        );
        if (questPrototype?.name) {
          return (
            <>
              Provide an email to use the{" "}
              <Text size={"mediumBold"}>{questPrototype.name}</Text> template.
            </>
          );
        }
      }

      return "Provide an email to use this template.";
    }
    return undefined;
  });

  if (helperText) {
    return (
      <StyledText>
        <Text>{helperText}</Text>
      </StyledText>
    );
  }

  return null;
};

function useSyncedTargetUrl() {
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();
  const route = useAppRoute<"Login">();

  const targetUrlFromRouteParams = route.params?.url;
  const targetUrlFromState = useAppSelector(({ ui }) => ui.postLoginTargetUrl);

  useEffect(() => {
    if (targetUrlFromState) {
      try {
        const url = new URL(targetUrlFromState, ENV.appBaseUrl);
        const loginId = url.searchParams.get("loginId");
        url.searchParams.delete("loginId");
        const cleanedUrl = url.pathname + url.search;
        if (cleanedUrl !== targetUrlFromState) {
          // update the state to remove the "loginId"
          dispatch(setPostLoginTargetUrl(cleanedUrl));
        }

        const updatedParams: CombinedRoutesParamList["Login"] = {};
        if (loginId && loginId !== route.params?.loginId) {
          updatedParams.loginId = loginId;
        }
        if (cleanedUrl && cleanedUrl !== targetUrlFromRouteParams) {
          // if we have a target URL in the state, but it doesn't match the route params,
          // then we need to update the route params
          updatedParams.url = cleanedUrl;
        }

        if (Object.keys(updatedParams).length > 0 && navigation.isFocused()) {
          navigation.setParams(updatedParams);
        }
      } catch (e) {
        console.warn("Failed to parse postLoginTargetUrl.", e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUrlFromState]);

  useEffectOnce(() => {
    if (
      targetUrlFromRouteParams &&
      targetUrlFromRouteParams !== targetUrlFromState
    ) {
      // if we have a target URL in the route params, but it doesn't match the state,
      // then we need to update the state
      dispatch(setPostLoginTargetUrl(targetUrlFromRouteParams));
    }
  });
}

interface LinkedTextProps {
  children: string;
  disabled?: boolean;
  onPress: (event: GestureResponderEvent) => void;
}

interface StyledLinkedTextProps {
  children: string;
  disabled: boolean;
}

const LinkedText = ({ onPress, children, disabled }: LinkedTextProps) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <StyledLinkedText disabled={!!disabled} size="small">
        {children}
      </StyledLinkedText>
    </TouchableWithoutFeedback>
  );
};

const StyledButton = styled(Button)`
  margin-top: 5px;
`;
const PoliciesView = styled.View`
  flex-direction: row;
`;

const InputWrapper = styled.View`
  width: 100%;
  margin-bottom: 40px;
`;

const SMSConsent = styled.View`
  align-self: center;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.cardInCard};
  border-radius: 20px;
  padding: 10px 20px;
`;

const StyledLinkedText = styled(Text)<StyledLinkedTextProps>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  text-decoration-line: underline;
  margin-bottom: 14px;
  ${Platform.OS === "web" ? `cursor: pointer` : ``}
`;

const StyledEmailPhoneInput = styled(EmailPhoneInput)`
  margin-bottom: 13px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 13px;
`;

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 20px;
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

const PressableLink = styled(Pressable)`
  text-align: center;
  margin-top: 20px;
`;
const StyledFadeInView = styled(FadeInView)`
  align-items: center;
`;

const KioskModeContainer = styled.View`
  margin-top: 40px;
  align-items: center;
`;

const SmallSpacer = styled.View`
  margin-bottom: 14px;
`;

const IconWrapper = styled.View`
  color: ${({ theme }) => theme.primary};
  height: 40px;
  margin-bottom: 40px;
`;
