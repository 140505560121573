import React, { useContext } from "react";

interface FullQuestContext {
  templateId: string;
  currentQuestPrototypeId: string;
  name: string;
  advancedMode: boolean;
  isReady: true;
}
interface LoadingQuestContext {
  templateId: string;
  advancedMode: boolean;
  isReady: false;
}

export const QuestContext = React.createContext<
  FullQuestContext | LoadingQuestContext
>({
  templateId: "",
  advancedMode: false,
  isReady: false,
});

export function isReady(
  questContext: FullQuestContext | LoadingQuestContext
): questContext is FullQuestContext {
  return questContext.isReady;
}

type ExpectedQuestContext<T extends { okIfNotReady: boolean } | undefined> =
  T extends { okIfNotReady: true }
    ? FullQuestContext | LoadingQuestContext
    : FullQuestContext;
export const useQuestContext = <
  T extends { okIfNotReady: boolean } | undefined
>(
  options?: T
): ExpectedQuestContext<T> => {
  const questContext = useContext(QuestContext);
  if (!questContext) {
    throw new Error(
      "Attempted to use QuestContext outside of a QuestContext Provider"
    );
  }
  if (!options?.okIfNotReady && !isReady(questContext)) {
    throw new Error(`Expected Quest context to be ready!`);
  }
  return questContext as ExpectedQuestContext<T>;
};
