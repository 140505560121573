import React, { useCallback, useRef } from "react";
import type { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import type { CompletionActionComponentProps as CompletionActionBaseProps } from "@app/components/completionAction";
import { RevealText } from "@app/components/questkit/revealText";
import { Analytics } from "@app/analytics";

const changeText = (
  item: CompletionActionRenderData,
  text: string
): CompletionActionRenderData => ({
  ...item,
  data: {
    text: text,
  },
  version: item.version + 1,
});

export const TextRevealReward = (props: CompletionActionBaseProps) => {
  const {
    item,
    editMode,
    onCompletionActionChange,
    onCompletionActionTouched,
  } = props;
  const itemRef = useRef(item);
  itemRef.current = item;
  const text = item.data.text || "";

  const show = editMode || (item.status === "COMPLETED" && !!text);

  const prototypeId = item.prototype.id;
  const onRevealTextChange = useCallback(
    (newText: string) => {
      Analytics.trackEventDebounced(prototypeId, "Set Text Reward Value");
      onCompletionActionChange?.(changeText(itemRef.current, newText));
      onCompletionActionTouched?.();
    },
    [onCompletionActionChange, onCompletionActionTouched, prototypeId]
  );
  return (
    <>
      {show && (
        <RevealText
          text={text}
          placeholder="Reveal Text"
          onBlur={onCompletionActionTouched}
          onRevealTextChange={onRevealTextChange}
          editable={editMode}
        />
      )}
    </>
  );
};
