import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import type { ItemRenderData } from "@app/types/itemRenderData";
import { ItemInstanceData } from "@app/types/itemRenderData";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  ItemTypesThatCanBeCompletionActions,
  CompletionActionRenderData,
} from "@app/types/completionActionRenderData";
import {
  extractPersistableItemInstanceData,
  validateItem,
} from "@questmate/common";
import isEqual from "react-fast-compare";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { Analytics } from "@app/analytics";
import promisePoller from "promise-poller";
import { useIsFocused } from "@react-navigation/native";
import {
  ItemType,
  JsonObject,
  QuestInstanceDetail,
  ReviewResult,
  RewardInstance,
} from "@questmate/openapi-spec";
import {
  fetchQuestInstance,
  fetchRewardInstance,
  submitQuestInstance,
  updateItemInstance,
} from "@app/util/client/requests/quests";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { useRequest, UseRequestResult } from "@app/util/client/requests";
import produce, { Draft } from "immer";

interface LocalItemMap {
  [key: string]: ItemRenderData;
}

export type OnItemChangeHandler = (
  item: ItemRenderData,
  options?: {
    /**
     * Set to `true` to skip sending a save request.
     */
    skipSave?: boolean;
    /**
     * Set to `true` to avoid calling `kioskInactivityTimeoutManager.reportActivityOccurred()`
     * This allows us to avoid marking a Quest run as dirty when a user has not interacted with the Quest.
     */
    skipActivityReport?: boolean;
  }
) => void;

interface useQuestInstanceData {
  completionActions: CompletionActionRenderData[];
  items: ItemRenderData[];
  submitQuestLoading: boolean;
  onItemChange: OnItemChangeHandler;
  onItemValidate: (itemId: string) => void;
  onSubmit: () => Promise<QuestInstanceDetail>;
  onReview: (reviewResult: ReviewResult) => void;

  SWR: UseRequestResult<QuestInstanceDetail>;
}

export const useQuestInstance = (
  questInstanceId: string
): useQuestInstanceData => {
  const {
    markRecentlySubmittedByUser,
    preSubmitPromiseTracker,
    publicQuestSessionToken,
  } = useQuestViewContext(["RUN"]);
  const isFocused = useIsFocused();

  const SWR = useRequest(
    fetchQuestInstance(questInstanceId, publicQuestSessionToken),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: questInstance, mutate: questInstanceMutate } = SWR;

  // Items
  const [localItemMap, setLocalItemMap_] = useState<LocalItemMap | undefined>();
  const localItemMapRef = useRef<LocalItemMap | undefined>();
  const snackbarContext = useContext(SnackbarContext);

  const setLocalItemMap = useCallback(
    (setList: (list: LocalItemMap) => LocalItemMap) => {
      const newList = setList(localItemMapRef.current!);
      localItemMapRef.current = newList;
      setLocalItemMap_(newList);
    },
    [localItemMapRef, setLocalItemMap_]
  );

  const [submitQuestLoading, setSubmitQuestLoading] = useState(false);

  const persistItemData = useCallback(
    async (
      questInstanceId: string,
      itemPrototypeId: string,
      itemType: ItemType,
      version: number,
      data: JsonObject
    ): Promise<void> => {
      await updateItemInstance(
        questInstanceId,
        itemPrototypeId,
        {
          version: version,
          data: extractPersistableItemInstanceData(itemType, data),
        },
        publicQuestSessionToken
      );
    },
    [publicQuestSessionToken]
  );

  const debouncedPersistItemData = useMemo(
    () =>
      AwesomeDebouncePromise(persistItemData, 500, {
        onlyResolvesLast: true,
        key: (...args) => args[1],
      }),
    [persistItemData]
  );

  useEffect(() => {
    // Need to put localItemMap into state and use this effect,
    // as data can be manipulated via onItemChange().
    if (questInstance) {
      let updatedLocalItemMap = {} as { [key: string]: ItemInstanceData };

      const rawItems = questPrototype.items.filter(
        (item) => !item.isCompletionAction
      );
      for (const itemPrototype of rawItems) {
        const itemInstance = rawItemInstances.find(
          (itemInstance) => itemInstance.prototype.id === itemPrototype.id
        )!;

        updatedLocalItemMap[itemPrototype.id] = {
          prototype: itemPrototype,
          subquestInstances: itemInstance.subquestInstances,
          version: 0,
          data: {},
        };
      }

      for (const serverItemData of rawItemInstances) {
        const localItemData = updatedLocalItemMap[serverItemData.prototype.id];

        if (
          serverItemData.version === localItemData.version &&
          isEqual(localItemData.data, serverItemData.data)
        ) {
          // If versions and data match, skip making changes to the local state.
          continue;
        }

        if (serverItemData.version >= localItemData.version) {
          const conflictResolvedData = serverItemData.data;

          updatedLocalItemMap = {
            ...updatedLocalItemMap,
            [serverItemData.prototype.id]: {
              prototype: localItemData.prototype,
              subquestInstances: serverItemData.subquestInstances,
              version: serverItemData.version,
              data: conflictResolvedData,
            },
          };
        }
      }

      setLocalItemMap(() => updatedLocalItemMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questInstance]);

  const onItemValidate = useCallback(
    (itemId: string) => {
      setLocalItemMap((itemMap) => {
        const item = itemMap[itemId];
        if (!item) {
          return itemMap;
        }
        const validateResult = validateItem({
          type: item.prototype.type,
          required: item.prototype.required,
          data: item.data,
        });
        return {
          ...itemMap,
          [item.prototype.id]: { ...item, validateResult },
        };
      });
    },
    [setLocalItemMap]
  );

  const onItemChange = useCallback(
    (
      item: ItemRenderData,
      options?: { skipSave?: boolean; skipActivityReport?: boolean }
    ) => {
      const { skipSave = false } = options ?? {};
      setLocalItemMap((itemMap) => ({
        ...itemMap,
        [item.prototype.id]: item,
      }));
      if (skipSave) {
        return;
      }
      debouncedPersistItemData(
        questInstanceId,
        item.prototype.id,
        item.prototype.type,
        item.version,
        item.data
      ).catch((e) => {
        console.log("Failed to persist item data", e.response);
      });
    },
    [questInstanceId, setLocalItemMap, debouncedPersistItemData]
  );

  const onSubmit = useCallback(async (): Promise<QuestInstanceDetail> => {
    Analytics.trackEvent("Try Submit Quest", {
      questInstanceId: questInstanceId,
    });

    setSubmitQuestLoading(true);

    await preSubmitPromiseTracker.allTrackedPromisesAreComplete();

    const validateAllItemsForSubmit = (): boolean => {
      const itemMapWithUpdatedValidationResults = Object.fromEntries(
        Object.entries(localItemMapRef.current!).map(([itemId, item]) => {
          const validateResult = validateItem({
            type: item.prototype.type,
            required: item.prototype.required,
            data: item.data,
          });
          return [itemId, { ...item, validateResult }];
        })
      );

      if (
        !isEqual(localItemMapRef.current, itemMapWithUpdatedValidationResults)
      ) {
        setLocalItemMap(() => itemMapWithUpdatedValidationResults);
      }

      return Object.values(itemMapWithUpdatedValidationResults)
        .filter((item) => !!item.prototype.name) // filter out unnamed items
        .every((item) => item.validateResult.valid);
    };

    const allValid = validateAllItemsForSubmit();
    if (!allValid) {
      setSubmitQuestLoading(false);
      return Promise.reject();
    }
    const itemVersions = Object.entries(localItemMapRef.current!).map(
      ([itemPrototypeId, { version, data, prototype }]) => {
        return {
          id: `${questInstanceId}|${itemPrototypeId}`,
          version,
          data: extractPersistableItemInstanceData(prototype.type, data),
        };
      }
    );
    return submitQuestInstance(
      questInstanceId,
      {
        itemVersions: itemVersions,
      },
      publicQuestSessionToken
    )
      .then((updatedInstanceData) => {
        if (updatedInstanceData) {
          void questInstanceMutate(updatedInstanceData, false);
        }

        setSubmitQuestLoading(false);
        markRecentlySubmittedByUser();
        return updatedInstanceData;
      })
      .catch((error) => {
        console.error("Failed to submit quest!", error);
        snackbarContext.sendMessage(
          "Unable to submit Quest. Please try again.",
          SnackbarSeverity.WARNING
        );
        setSubmitQuestLoading(false);
        throw error;
      });
  }, [
    markRecentlySubmittedByUser,
    preSubmitPromiseTracker,
    publicQuestSessionToken,
    questInstanceId,
    questInstanceMutate,
    setLocalItemMap,
    snackbarContext,
  ]);

  const onReview = useCallback(
    (reviewResult: ReviewResult) =>
      questInstanceMutate(
        (formInstance) =>
          produce(formInstance, (draft) => {
            draft!.requestingUser.reviewResult = reviewResult;
          }),
        true
      ),
    [questInstanceMutate]
  );

  const pollingRewardsRef = useRef<Record<string, Promise<RewardInstance>>>({});

  const isFocusedRef = useRef(isFocused);
  isFocusedRef.current = isFocused;

  useEffect(() => {
    if (!isFocused) {
      pollingRewardsRef.current = {};
      return;
    }

    (questInstance?.rewardInstances || []).forEach((reward) => {
      const currentlyPolling = pollingRewardsRef.current[reward.prototype.id];
      if (reward.status === "PENDING" && !currentlyPolling) {
        const poller = createPollerForRewardInstance(
          questInstanceId,
          reward.prototype.id,
          false,
          publicQuestSessionToken
        );

        pollingRewardsRef.current[reward.prototype.id] = poller;

        poller
          .then((reward) => {
            void questInstanceMutate(
              produce((draft: Draft<QuestInstanceDetail | undefined>) => {
                const index = draft!.rewardInstances.findIndex(
                  ({ prototype }) => prototype.id === reward.prototype.id
                );
                draft!.rewardInstances[index] = reward;
              })
            );
          })
          .catch((e) => {
            console.error(
              "Completion action did not complete successfully.",
              e
            );
          });
      }
    });
  }, [
    questInstance?.rewardInstances,
    isFocused,
    questInstanceId,
    publicQuestSessionToken,
    questInstanceMutate,
  ]);

  const completionActions = useMemo(() => {
    const completionActionItems = questInstance?.itemInstances
      .filter((item) => item.isCompletionAction)
      .map((item): CompletionActionRenderData => {
        const itemPrototype = (questInstance?.prototype.items || []).find(
          (itemPrototype) => itemPrototype.id === item.prototype.id
        )!;
        return {
          data: item.data,
          version: item.version,
          prototype: {
            id: itemPrototype.id,
            position: itemPrototype.position,
            name: itemPrototype.name,
            type: itemPrototype.type as ItemTypesThatCanBeCompletionActions,
          },
        };
      });

    const completionActionRewards = questInstance?.rewardInstances.map(
      (reward) => {
        return {
          status: reward.status,
          data: reward.data,
          prototype: (questInstance?.prototype.rewards || []).find(
            (rewardPrototype) => rewardPrototype.id === reward.prototype.id
          ),
        } as CompletionActionRenderData;
      }
    );

    return [
      ...(completionActionItems || []),
      ...(completionActionRewards || []),
    ].sort((a, b) => {
      if (a.prototype.position === b.prototype.position) {
        return a.prototype.id.localeCompare(b.prototype.id);
      }
      return a.prototype.position - b.prototype.position;
    });
  }, [
    questInstance?.prototype.rewards,
    questInstance?.rewardInstances,
    questInstance?.prototype.items,
    questInstance?.itemInstances,
  ]);

  const items = useMemo(() => {
    return (localItemMap ? Object.values(localItemMap) : [])
      .filter((item) => !!item.prototype.name) // Remove items without a name
      .sort((a, b) => a.prototype.position - b.prototype.position);
  }, [localItemMap]);

  if (!questInstance) {
    return {
      items: [],
      completionActions: [],
      onSubmit: () => {
        throw new Error("onSubmit called before Quest Instance received.");
      },
      onReview: () => {
        throw new Error("onReview called before Quest Instance received.");
      },
      onItemChange: () => {
        throw new Error("onItemChange called before Quest Instance received.");
      },
      onItemValidate: () => {
        throw new Error(
          "onItemValidate called before Quest Instance received."
        );
      },
      submitQuestLoading: false,
      SWR,
    };
  }

  const questPrototype = questInstance.prototype;
  const rawItemInstances = questInstance.itemInstances.filter(
    (item) => !item.isCompletionAction
  );

  return {
    completionActions,
    items,
    submitQuestLoading,
    onItemChange,
    onItemValidate,
    onSubmit,
    onReview,
    SWR,
  };
};

export const createPollerForRewardInstance = (
  questInstanceId: string,
  rewardPrototypeId: string,
  failFast: boolean,
  publicQuestSessionToken?: string
): Promise<RewardInstance> =>
  promisePoller<RewardInstance>({
    taskFn: () =>
      fetchRewardInstance(
        questInstanceId,
        rewardPrototypeId,
        publicQuestSessionToken
      ).execute(),
    shouldContinue(error: unknown, reward?: RewardInstance): boolean {
      if (error && failFast) {
        return false;
      }
      return reward?.status !== "COMPLETED" && reward?.status !== "FAILED";
    },
    timeout: 5 * 1000,
    retries: 100,
    strategy: "linear-backoff",
    start: 250,
    increment: 250,
    masterTimeout: 3 * 60 * 1000,
  });
