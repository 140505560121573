import { createRequest } from "@app/util/client/requests/index";
import * as apitypes from "@questmate/openapi-spec";

export const fetchBillingSummary = () => {
  return createRequest<apitypes.Internal.BillingSummaryList.ResponseBody>(
    "get",
    "/internal/billing/summary"
  )();
};

export const fetchBillingPlans = (
  query?: apitypes.Internal.BillingPlansList.RequestQuery
) => {
  const searchParams = new URLSearchParams();
  if (query?.coupon) {
    searchParams.set("coupon", query.coupon);
  }
  return createRequest<apitypes.Internal.BillingPlansList.ResponseBody>(
    "get",
    `/internal/billing/plans?${searchParams.toString()}`
  )();
};
