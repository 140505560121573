import { useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { store, useAppSelector } from "@app/store";
import { selectLoggedInUserId } from "@app/store/auth";
import { useRequest } from "@app/util/client/requests";
import {
  selectUserById,
  selectUsersWithIds,
  User,
} from "@app/store/cache/users";
import {
  isUserEntry,
  UserListController,
  UserListEntry,
  UserListEvents,
} from "@app/components/questkit/UserList/UserList.controller";
import { getUsers } from "@app/util/client/requests/users";
import { IdentifierParseResult } from "@app/screens/login/IdentifierParser";
import { PromiseTracker } from "@app/util/PromiseTracker";
import isEqual from "react-fast-compare";

export type UserEntryViewModel =
  | IdentifierEntryViewModel
  | LoadingEntryViewModel
  | ReadyEntryViewModel;

type IdentifierEntryViewModel = {
  status: "LOOKING_UP_USER";
  identifier: IdentifierParseResult;
};
type LoadingEntryViewModel = {
  status: "LOADING";
  userId: string;
} & LoggedInUserData;
type ReadyEntryViewModel = {
  status: "READY";
  userId: string;
} & LoggedInUserData &
  Omit<User, "id">;

type LoggedInUserData =
  | {
      isLoggedInUser: true;
      isInList: boolean;
    }
  | {
      isLoggedInUser: false;
    };

type UseUserListResult = {
  entries: UserEntryViewModel[];
  userList: UserListController;
  waitForPendingEntries: () => Promise<void>;
};
export const useUserList = (
  entriesFromParent: UserListEntry[],
  options?: { alwaysIncludeLoggedInUser: boolean }
): UseUserListResult => {
  const alwaysIncludeLoggedInUser = options?.alwaysIncludeLoggedInUser ?? false;
  const userList = useRef(new UserListController(entriesFromParent)).current;

  useEffect(() => {
    userList.handleUpstreamEntriesChanged(entriesFromParent);
  }, [entriesFromParent, userList]);

  const [entries, setEntries] = useState<UserListEntry[]>(
    // init fn to avoid cloning in getter on every re-render
    () => userList.entries
  );
  useEffectOnce(() => {
    const listener = (event: UserListEvents["changeEntries"]) =>
      setEntries(event.entries);
    userList.on("changeEntries", listener);
    return () => {
      userList.off("changeEntries", listener);
    };
  });

  const loggedInUserId = useAppSelector(selectLoggedInUserId);
  const userIds = useUniqueList([
    ...(loggedInUserId ? [loggedInUserId] : []),
    ...entries.filter(isUserEntry).map((e) => e.userId),
  ]);
  useRequest(getUsers(userIds));

  const usersById = useAppSelector((state) =>
    selectUsersWithIds(state, userIds)
  );

  const loggedInUserIsInList = entries.some(
    (entry) => isUserEntry(entry) && entry.userId === loggedInUserId
  );
  const entryViewModels = useMemo(() => {
    const allEntriesToRender = [
      ...(loggedInUserId && alwaysIncludeLoggedInUser && !loggedInUserIsInList
        ? [{ userId: loggedInUserId }]
        : []),
      ...entries,
    ].sort((a, b) =>
      // Always put the logged-in user first in the list
      isUserEntry(a) && a.userId === loggedInUserId
        ? -1
        : isUserEntry(b) && b.userId === loggedInUserId
        ? 1
        : 0
    );
    return allEntriesToRender.map((entry) =>
      mapEntryToViewModel(
        entry,
        usersById,
        loggedInUserId,
        loggedInUserIsInList
      )
    );
  }, [
    loggedInUserId,
    alwaysIncludeLoggedInUser,
    loggedInUserIsInList,
    entries,
    usersById,
  ]);

  const waitForPendingEntries = usePromiseTrackingForPendingEntries(
    userList,
    entryViewModels
  );

  return { userList, waitForPendingEntries, entries: entryViewModels };
};

function mapEntryToViewModel(
  entry: UserListEntry,
  usersById: Record<string, User | undefined>,
  loggedInUserId: string | null,
  loggedInUserIsInList: boolean
) {
  if (isUserEntry(entry)) {
    const user = usersById[entry.userId];
    if (user?.displayName) {
      const { id: _id, ...rest } = user;
      return {
        ...rest,
        status: "READY" as const,
        userId: entry.userId,
        ...(entry.userId === loggedInUserId
          ? { isLoggedInUser: true as const, isInList: loggedInUserIsInList }
          : { isLoggedInUser: false as const }),
      };
    } else {
      return {
        status: "LOADING" as const,
        userId: entry.userId,
        ...(entry.userId === loggedInUserId
          ? { isLoggedInUser: true as const, isInList: loggedInUserIsInList }
          : { isLoggedInUser: false as const }),
      };
    }
  } else {
    return {
      status: "LOOKING_UP_USER" as const,
      identifier: entry.identifier,
    };
  }
}

function useUniqueList<T extends string | number>(list: T[]) {
  const prevListRef = useRef<T[]>([]);
  return useMemo(() => {
    const set = new Set(list);
    const uniqueSortedList = [...set].sort();
    if (!isEqual(uniqueSortedList, prevListRef)) {
      prevListRef.current = uniqueSortedList;
    }
    return prevListRef.current;
  }, [list]);
}

/**
 * Track entries that are not ready, and wait for them to be ready.
 * This is used upstream to wait for all entries to be ready when `flush()` is called.
 */
function usePromiseTrackingForPendingEntries(
  userList: UserListController,
  entries: UserEntryViewModel[]
) {
  const entriesRef = useRef(entries);
  entriesRef.current = entries;

  const { trackPromise, allTrackedPromisesAreComplete } = useRef(
    new PromiseTracker()
  ).current;

  /**
   * Track IdentifierEntry lookups and wait for them to be loaded into cache.
   */
  useEffectOnce(() => {
    const listener = (event: UserListEvents["lookupUsers"]) => {
      trackPromise(event.lookupCompletedPromise);
    };
    userList.on("lookupUsers", listener);
    return () => {
      userList.off("lookupUsers", listener);
    };
  });

  /**
   * Track UserEntry that do not yet have user data loaded into the cache.
   */
  const prevLoadingUserIdsRef = useRef<string[]>([]);
  useEffect(() => {
    const prevLoadingUserIds = prevLoadingUserIdsRef.current;
    const newLoadingUserIds = entries
      .map((entry) => {
        if (entry.status === "LOADING") {
          return entry.userId;
        }
      })
      .filter((userId): userId is string => Boolean(userId))
      .sort();
    prevLoadingUserIdsRef.current = newLoadingUserIds;
    if (
      !isEqual(prevLoadingUserIds, newLoadingUserIds) &&
      newLoadingUserIds.length > 0
    ) {
      trackPromise(
        new Promise<void>((resolve) => {
          const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            const allUsersLoadedIntoCache = newLoadingUserIds
              .filter((userId) =>
                // Exclude userIds that have since been removed from the entries list.
                // This could happen if there was an error, and it was removed from the list.
                entriesRef.current.some(
                  (entry) => isUserEntry(entry) && entry.userId === userId
                )
              )
              .every((userId) => !!selectUserById(state, userId)?.displayName);

            if (allUsersLoadedIntoCache) {
              unsubscribe();
              resolve();
            }
          });
        })
      );
    }
  }, [entries, trackPromise]);
  return allTrackedPromisesAreComplete;
}
