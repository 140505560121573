import React, { useCallback, useMemo } from "react";
import styled from "styled-components/native";
import { QKTextProps, TextInput } from "@app/components/questkit/text";
import AnimatedText from "../animated/animatedText";
import { StyleProp, ViewStyle } from "react-native";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

interface RevealTextProps {
  text: string;
  placeholder?: string;
  onRevealTextChange?: (text: string) => void;
  onBlur?: () => void;
  editable?: boolean;
  style?: StyleProp<ViewStyle>;
}

const TEXT_SIZE_CHARACTER_CUTOFF = 60;
export const RevealText: React.FC<RevealTextProps> = ({
  text: initialText,
  onRevealTextChange,
  onBlur,
  editable,
  placeholder,
  style = {},
}) => {
  const [text, setRevealText, textRef] = useStateWithRef(initialText);

  const textSize = useMemo((): QKTextProps["size"] => {
    return (text?.length ?? 0) > TEXT_SIZE_CHARACTER_CUTOFF
      ? "large"
      : "xlarge";
  }, [text]);

  const onChangeText = useCallback(
    (text: string) => {
      const singleLineText = text.replace(/[\r\n]+/g, "");
      if (singleLineText !== textRef.current) {
        setRevealText(singleLineText);
        onRevealTextChange?.(singleLineText);
      }
      return singleLineText;
    },
    [onRevealTextChange, setRevealText, textRef]
  );

  if (!editable) {
    return <AnimatedText content={text} style={style} size={textSize} />;
  } else {
    return (
      <RevealTextWrapper style={style}>
        <RevealTextInput
          value={text || ""}
          placeholder={placeholder}
          size={textSize}
          scrollEnabled={false}
          multiline={true}
          onChangeText={onChangeText}
          blurOnSubmit={true}
          onBlur={onBlur}
          textAlign="center"
          autoCorrect={true}
          autoFocus={false}
        />
      </RevealTextWrapper>
    );
  }
};

const RevealTextWrapper = styled.View`
  padding-horizontal: 20px;
  align-items: center;
  justify-content: center;
`;

const RevealTextInput = styled(TextInput)`
  width: 100%;
  text-align: center;
`;
