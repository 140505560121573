import {
  createSideBarItemRowIcon,
  SideBarItem,
} from "@app/navigation/sideBar/items/SideBarItem";
import React, { useRef } from "react";

const SearchIcon = createSideBarItemRowIcon("search");
export const SideBarQuestsItem: React.FC = () => {
  const screenParams = useRef({ filters: {} }).current;
  return (
    <SideBarItem
      label={"All Quests"}
      screenName={"Templates"}
      screenParams={screenParams}
      componentLeft={SearchIcon}
    />
  );
};
