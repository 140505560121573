import React, { useCallback, useContext, useRef, useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { View } from "react-native";
import { buildAssigneeNotificationsString } from "@app/components/snackbar/MessageBuilders";
import { updateQuests } from "@app/util/cacheMod";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { startQuestPrototype } from "@app/util/client/requests/quests";
import { UserPickerController } from "@app/components/questkit/UserList/UserPicker";
import { AssigneePicker } from "@app/components/questkit/AssigneePicker";
import { logOut } from "@app/screens/LogoutScreen";
import { setKioskQuestPublicId } from "@app/store/UI";
import { useAppDispatch, useAppSelector } from "@app/store";
import { InfoText } from "@app/components/questkit/InfoText";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { usePromise } from "@app/util/usePromise";
import { sentry } from "@app/util/sentry";
import { selectQuestStartTriggerByComboId } from "@app/store/cache/questStartTriggers";
import isEqual from "react-fast-compare";

export type StartQuestDialogProps = {
  setShowStartQuestModal: (showStartQuestModal: boolean) => void;
  templateId: string;
  rootQuestPrototypeId: string;
  name: string;
};

const StartQuestDialog: React.FC<StartQuestDialogProps> = ({
  setShowStartQuestModal,
  templateId,
  rootQuestPrototypeId,
  name: initialName,
}) => {
  const snackbarContext = useContext(SnackbarContext);

  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  const [questName, setQuestName] = useState(initialName);
  const assigneePickerRef = useRef<UserPickerController>(null);

  const [questStarted, setQuestStarted] = useState(false);

  useQuestPrototype(rootQuestPrototypeId);
  const data = useAppSelector((state) => {
    const qp = selectQuestPrototypeById(state, rootQuestPrototypeId);
    if (!qp) {
      return undefined;
    }
    const firstStartTriggerId = qp.startTriggerIds?.[0];
    let firstStartTrigger;
    if (firstStartTriggerId) {
      firstStartTrigger = selectQuestStartTriggerByComboId(
        state,
        `${rootQuestPrototypeId}:${firstStartTriggerId}`
      );
    }
    return {
      name: qp.name,
      firstStartTrigger: firstStartTrigger
        ? {
            type: firstStartTrigger.type,
            config: firstStartTrigger.config,
            startConfigurationId: firstStartTrigger.startConfigurationId,
          }
        : undefined,
    };
  }, isEqual);

  const closeStartQuestModal = useCallback(() => {
    setShowStartQuestModal(false);
  }, [setShowStartQuestModal]);

  const { execute: onStartQuest, isLoading: startQuestLoading } = usePromise(
    async () => {
      const cleanedQuestName = questName.trim();
      if (!cleanedQuestName) {
        snackbarContext.sendMessage(
          `Please give your Quest a name.`,
          SnackbarSeverity.WARNING
        );
        return;
      }

      const users = await assigneePickerRef.current!.flush().catch((e) => {
        snackbarContext.sendMessage(
          `Couldn't set Assignees on Quest Run. Please try again.`,
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: { message: "Failed to flush assignee picker" },
        });
        throw e;
      });

      return startQuestPrototype(rootQuestPrototypeId, {
        assignees: users.map((user) => ({ userId: user.id })),
        ...(cleanedQuestName !== data?.name
          ? {
              name: cleanedQuestName,
            }
          : {}),
      })
        .then((startQuestData) => {
          setQuestStarted(true);
          void updateQuests();

          const assignments = startQuestData.assignments;

          const inviteNotificationsString =
            buildAssigneeNotificationsString(assignments);

          setTimeout(() => {
            closeStartQuestModal();

            navigation.navigate("Template", {
              templateId: templateId,
              screen: "QuestRuns",
            });

            setTimeout(() => {
              if (
                assignments.length === 0 &&
                startQuestData.sharedInstance?.id
              ) {
                navigation.navigate("AdminQuestRun", {
                  templateId: startQuestData.quest.id,
                  questInstanceId: startQuestData.sharedInstance.id,
                });
                snackbarContext.sendMessage(`Run created.`);
              } else if (
                assignments.length === 1 &&
                startQuestData.sharedInstance?.id
              ) {
                const isAssignedToUser =
                  assignments[0].assignee.id === getLoggedInUserId(true);

                if (isAssignedToUser) {
                  navigation.navigate("QuestInstance", {
                    id: assignments[0].formInstance!.id!,
                  });

                  snackbarContext.sendMessage(`Run created.`);
                } else {
                  navigation.navigate("AdminQuestRun", {
                    templateId: startQuestData.quest.id,
                    questInstanceId: startQuestData.sharedInstance.id,
                  });
                  snackbarContext.sendMessage(
                    `Run created.${inviteNotificationsString}`
                  );
                }
              } else {
                navigation.navigate("Template", {
                  templateId: startQuestData.quest.id,
                  screen: "QuestRuns",
                  params: {
                    filters: {
                      status: ["OPEN"],
                    },
                  },
                });
                snackbarContext.sendMessage(
                  `Runs created.${inviteNotificationsString}`
                );
              }
            }, 0);
          }, MODAL_CLOSE_DELAY);
        })
        .catch((e) => {
          console.log("Failed to start Quest Run", e);
          snackbarContext.sendMessage(
            `Couldn't start Quest Run.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, {
            extra: {
              templateId,
              rootQuestPrototypeId,
            },
          });
        });
    }
  );

  const enterKioskMode = useCallback(() => {
    const publicId = data?.firstStartTrigger?.config.publicId;
    if (!publicId) {
      console.error("Missing publicId for Kiosk Mode");
      return;
    }
    dispatch(setKioskQuestPublicId(publicId));
    closeStartQuestModal();
    setTimeout(() => {
      logOut({
        reason: "Switching to Kiosk Mode",
        screenName: "PublicAssignment",
        screenParams: {
          id: publicId,
        },
      });
    }, 150);
  }, [closeStartQuestModal, dispatch, data]);

  return data?.firstStartTrigger === undefined ? null : (
    <StartQuestDialogScrollView keyboardShouldPersistTaps="always">
      <View onStartShouldSetResponder={() => true}>
        {data.firstStartTrigger.type === "KIOSK" ? (
          <>
            <InfoText
              text="Starting Kiosk Mode will log you out on this device to protect your account from
              unauthorized access."
            />

            <StyledStartQuestButton
              onPress={enterKioskMode}
              style={{ width: "100%" }}
              title="Continue"
            />
          </>
        ) : (
          <>
            <HeaderText title="Name" />
            <Section>
              <StyledTextInput
                placeholder={"Awesome Quest"}
                onChangeText={(questName: string) => setQuestName(questName)}
                value={questName}
                editable={!startQuestLoading && !questStarted}
              />
            </Section>
            <HeaderText title="Assignees" />
            <Section>
              <AssigneePicker
                startConfigurationId={
                  data.firstStartTrigger.startConfigurationId!
                }
                noInitialAssignees={data.firstStartTrigger.type !== "SCHEDULE"}
                ref={assigneePickerRef}
              />
            </Section>
            <StyledStartQuestButton
              onPress={onStartQuest}
              success={questStarted}
              loading={startQuestLoading}
              title="Run Quest"
            />
          </>
        )}
      </View>
    </StartQuestDialogScrollView>
  );
};

const StartQuestDialogScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    padding: 12,
  },
})``;

const StyledStartQuestButton = styled(Button)`
  margin-top: 20px;
`;

const Section = styled.View`
  margin-bottom: 20px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 10px;
`;

export default StartQuestDialog;
