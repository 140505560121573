import React, { useCallback, useMemo, useState } from "react";
import { Platform, View, ViewStyle } from "react-native";
import * as Haptics from "expo-haptics";

import styled from "styled-components/native";

import type {
  CompletionActionRenderData,
  CompletionActionType,
} from "@app/types/completionActionRenderData";
import ItemRowHeader from "../item/itemRowHeader";

import { DragHandlers } from "@app/components/questkit/dragPanel";

import { TextRevealReward } from "./components/text";
import { TremendousReward } from "./components/TremendousCard";
import { PlaceholderCompletionAction } from "@app/components/completionAction/components/placeholder";
import QKModal from "../modal";
import equal from "react-fast-compare";
import { Analytics } from "@app/analytics";
import { PostSlackMessageReward } from "./components/postSlackMessage";
import { CreateQuestReward } from "@app/components/completionAction/components/createQuest";
import { RunQuestScriptReward } from "./components/runQuestScript";
import { CustomItemV2CompletionAction } from "@app/components/completionAction/components/CustomV2";
import { ItemContextProvider } from "../item/ItemContextProvider";
import BasePressable from "@app/components/questkit/BasePressable";
import { Shadow } from "react-native-shadow-2";
import CompletionActionOptionsDialog, {
  completionActionTypeViewDataMap,
} from "@app/components/modal/completionActionOptionsDialog";
import Text from "@app/components/questkit/text";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { RewardContentContainer } from "@app/components/completionAction/components/RewardContainer";

export interface CompletionActionProps extends CompletionActionComponentProps {
  isDragged: boolean;
  onShowCompletionActionOptions?: () => void;
  dragHandlers?: DragHandlers;
  position: number;
  style?: ViewStyle;
}

interface ContentProps {
  editMode: boolean;
}

interface StyledViewProps {
  isDragged: boolean;
  editMode: boolean;
  hasValidationError: boolean;
}

export type CompletionActionComponentProps = {
  item: CompletionActionRenderData;
  onCompletionActionChange: (
    completionAction: CompletionActionRenderData
  ) => void;
  onCompletionActionDelete?: () => void;
  onCompletionActionTouched?: () => void;
  onCompletionActionValidationContextChange?: (
    validationContext: Record<string, unknown>
  ) => void;
  editMode: boolean;
  readOnly: boolean;
};

const getCompletionActionComponentByType = (
  props: CompletionActionComponentProps
) => {
  switch (props.item.prototype.type) {
    case "TEXT":
      return <TextRevealReward {...props} />;
    case "TREMENDOUS_REWARD":
      return <TremendousReward {...props} />;
    case "POST_SLACK_MESSAGE":
      return <PostSlackMessageReward {...props} />;
    case "CREATE_QUEST":
      return <CreateQuestReward {...props} />;
    case "RUN_QUEST_SCRIPT":
      return <RunQuestScriptReward {...props} />;
    case "CustomV2":
      return <CustomItemV2CompletionAction {...props} />;
    default:
      return <PlaceholderCompletionAction />;
  }
};

const noOp = () => {
  /* do nothing */
};

// TODO: We need to show something for these action types in ReadOnly & Preview views.
const TYPES_MISSING_PREVIEW_VIEW: CompletionActionType[] = [
  "TEXT",
  "POST_SLACK_MESSAGE",
  "TREMENDOUS_REWARD",
  "CREATE_QUEST",
];

const CompletionAction: React.FC<CompletionActionProps> = ({
  item,
  onCompletionActionChange,
  onCompletionActionDelete,
  onCompletionActionTouched,
  dragHandlers,
  editMode,
  readOnly,
  isDragged,
  onCompletionActionValidationContextChange,
  style = {},
}) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const completionActionComponent = getCompletionActionComponentByType({
    item,
    onCompletionActionChange: readOnly ? noOp : onCompletionActionChange,
    onCompletionActionDelete,
    onCompletionActionValidationContextChange,
    onCompletionActionTouched,
    editMode,
    readOnly,
  });

  const onLongPress = useCallback(() => {
    if (editMode && !readOnly) {
      if (Platform.OS === "ios") {
        void Haptics.impactAsync();
      }
      dragHandlers?.onDragStart();
    }
  }, [dragHandlers, editMode, readOnly]);
  const onPressOut = useCallback(() => {
    if (editMode && !readOnly) {
      dragHandlers?.onDragHandleRelease();
    }
  }, [dragHandlers, editMode, readOnly]);

  const closeModal = useCallback(() => setShowOptionsModal(false), []);
  const openModal = useCallback(() => {
    Analytics.trackEvent("Open Completion Action Options Dialog");
    setShowOptionsModal(true);
  }, []);

  const validationErrors = useMemo(() => item.errors || [], [item.errors]);

  const questViewContext = useQuestViewContext();

  const isPreviewOrReadOnlyMode =
    questViewContext.viewContext === "PREVIEW" ||
    (questViewContext.viewContext === "MANAGE" && !editMode);

  const itemType = item.prototype.type;
  const hideItem = useMemo(() => {
    return (
      isPreviewOrReadOnlyMode && TYPES_MISSING_PREVIEW_VIEW.includes(itemType)
    );
  }, [isPreviewOrReadOnlyMode, itemType]);

  if (hideItem) {
    return null;
  }

  return (
    <ItemContextProvider
      editMode={editMode}
      prototypeId={item.prototype.id}
      position={item.prototype.position}
      isCompletionAction={true}
    >
      <StyledPressable
        style={style}
        testID={`${editMode ? "edit" : "fill"}-action-container`}
        onLongPress={onLongPress}
        onPressOut={onPressOut}
      >
        <StyledView
          isDragged={isDragged}
          editMode={editMode}
          hasValidationError={validationErrors.length > 0}
        >
          {editMode && (
            <ItemRowHeader
              typeName={
                completionActionTypeViewDataMap[item.prototype.type].label
              }
              onShowItemOptions={openModal}
              onDelete={onCompletionActionDelete}
              dragHandlers={dragHandlers}
            />
          )}

          <Content editMode={editMode}>
            {/* This should be a styled component, but for some reason that breaks rendering on web */}
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {completionActionComponent}
                {item.isTouched && validationErrors.length > 0 ? (
                  <ErrorWrapper>
                    <ErrorIcon icon="info" />
                    <Text size="medium" $warning>
                      {validationErrors[0]}
                    </Text>
                  </ErrorWrapper>
                ) : null}
              </View>
            </View>
          </Content>
        </StyledView>
      </StyledPressable>
      <QKModal
        showModal={showOptionsModal}
        setShowModal={setShowOptionsModal}
        title="Action Options"
      >
        <CompletionActionOptionsDialog
          completionAction={item}
          onCompletionActionChange={onCompletionActionChange}
          onModalClose={closeModal}
        />
      </QKModal>
    </ItemContextProvider>
  );
};

const StyledView = styled(Shadow).attrs<StyledViewProps>(
  ({ theme, isDragged }) => ({
    stretch: false,
    startColor: theme.background,
    distance: isDragged ? 5 : 0, // using disabled causes a flicker when drag starts
    paintInside: true,
  })
)<StyledViewProps>`
  ${({ theme, editMode, hasValidationError }) =>
    editMode
      ? `
      border-radius: 20px;
      background-color: ${theme.card.background};
      border-color: ${
        hasValidationError ? theme.warning : theme.card.background
      };
  `
      : hasValidationError
      ? `
      border-left-width: 1px;
      border-left-color: ${theme.warning};
  `
      : ""}
  max-width: 540px;
  align-self: center;

  width: 100%;
  overflow: hidden;
`;

const Content = styled.View<ContentProps>`
  margin-bottom: 8px;
  padding-vertical: 20px;
`;

const StyledPressable = styled(BasePressable)`
  ${Platform.OS === "web" ? `cursor: default` : ``}
`;

export default React.memo(CompletionAction, (prev, next) => {
  return (
    equal(prev.item, next.item) &&
    prev.position === next.position &&
    prev.editMode === next.editMode &&
    prev.readOnly === next.readOnly &&
    prev.dragHandlers?.onDragStart === next.dragHandlers?.onDragStart &&
    prev.isDragged === next.isDragged
  );
});

const ErrorWrapper = styled(RewardContentContainer)`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const ErrorIcon = styled(ItemInfoIcon)`
  color: ${({ theme }) => theme.warning};
`;
