import { Platform } from "react-native";
import createSecureStore, {
  ReduxPersistExpoSecureStore,
} from "redux-persist-expo-securestore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Action, Reducer, Store } from "redux";
import { Persistor } from "redux-persist/es/types";
import * as ReduxPersist from "redux-persist";
import { UIState } from "@app/store/UI";
import { sentry } from "@app/util/sentry";
import {
  PublicQuestSessionsState,
  filterExpiredSessions,
} from "@app/store/publicQuestAuth";

export function persistMainReducer<S, A extends Action = Action>(
  rootAppReducer: Reducer<S, A>
): Reducer<S, A> {
  return ReduxPersist.persistReducer(
    {
      storage: AsyncStorage,
      key: "main",
      version: 1,
    },
    rootAppReducer
  );
}

export function persistUIReducer<S extends UIState, A extends Action = Action>(
  rootAppReducer: Reducer<S, A>
): Reducer<S, A> {
  return ReduxPersist.persistReducer(
    {
      storage: AsyncStorage,
      key: "ui",
      version: 1,
      whitelist: ["sideBar", "kioskQuestPublicId", "questRunFilters"],
    },
    rootAppReducer
  );
}

export function persistPublicQuestAuthReducer<S, A extends Action = Action>(
  rootAppReducer: Reducer<S, A>
): Reducer<S, A> {
  const excludeExpiredSessionsOnRehydrate = ReduxPersist.createTransform(
    null,
    (outboundState, key) => {
      // Remove any expired sessions when rehydrating from stored state
      if (key === "sessions") {
        const sessions = (outboundState ||
          {}) as PublicQuestSessionsState["sessions"];

        const validSessions = filterExpiredSessions(sessions);

        const initialSessionCount = Object.keys(sessions).length;
        const validSessionCount = Object.keys(validSessions).length;
        if (validSessionCount !== initialSessionCount) {
          console.log(
            `Identified and removed ${
              initialSessionCount - validSessionCount
            } expired public Quest sessions.`
          );
        }

        return validSessions;
      }
      return outboundState;
    }
  );
  return ReduxPersist.persistReducer(
    {
      storage: AsyncStorage,
      key: "publicQuestAuth",
      version: 1,
      transforms: [excludeExpiredSessionsOnRehydrate],
    },
    rootAppReducer
  );
}

export function persistAuthReducer<S, A extends Action = Action>(
  rootAppReducer: Reducer<S, A>
): Reducer<S, A> {
  return ReduxPersist.persistReducer(
    {
      storage: secureStorage,
      key: "auth",
      version: 2,
      migrate: async (state, currentVersion) => {
        if (!state || typeof state !== "object" || currentVersion !== 2) {
          return state;
        }
        // migrate refresh token to its own key managed outside of redux
        sentry.addBreadcrumb({
          message: "Migrating auth state from version 1 to 2",
        });

        const existingStoredRefreshToken = await retrieveRefreshToken();
        const reduxStoredRefreshToken = (
          state as unknown as { refreshToken: string }
        ).refreshToken;
        if (!existingStoredRefreshToken && reduxStoredRefreshToken) {
          await storeRefreshToken(reduxStoredRefreshToken);
        }

        return {
          ...state,
          refreshToken: undefined,
        };
      },
    },
    rootAppReducer
  );
}

export function persistStore(store: Store): Persistor {
  return ReduxPersist.persistStore(store);
}

/** Note: SecureStorage is only available on mobile and stored values are LIMITED to 2048bytes of data
 * See https://docs.expo.dev/versions/latest/sdk/securestore/
 *
 * The 2048byte limit is not consistent across devices and represents the minimum limit
 * across all known mobile devices expo supports. Currently expo prints a warning, but plans
 * to throw an error in the future when trying to send values above 2048.
 *
 * TODO: Investigate a way to create a dynamic key for each public session so that
 *       they can safely be stored in secure storage. Only 6 sessions can be stored
 *       under the 2048byte limit, but we are not enforcing this limit.
 *       iOS SecureStorage has a higher limit and it is not causing issues at the moment.
 */
const secureStorage =
  Platform.OS === "web"
    ? AsyncStorage
    : (createSecureStore() as unknown as {
        // Types are wrong for this package
        // See this issue: https://github.com/Cretezy/redux-persist-expo-securestore/issues/24
        getItem: ReduxPersistExpoSecureStore["setItem"];
        setItem: ReduxPersistExpoSecureStore["getItem"];
        removeItem: ReduxPersistExpoSecureStore["removeItem"];
      });

export const storeRefreshToken = async (
  refreshToken: string | undefined
): Promise<void> => {
  if (refreshToken) {
    await secureStorage.setItem("refreshToken", refreshToken);
  } else {
    await secureStorage.removeItem("refreshToken");
  }
};

export const retrieveRefreshToken = async (): Promise<string | null> => {
  return await secureStorage.getItem("refreshToken");
};
