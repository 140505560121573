import EventEmitter from "events";
import { CreateUsersResponse } from "@questmate/openapi-spec";
import _ from "lodash";
import { store } from "@app/store";
import { selectAllUsers, usersLoaded } from "@app/store/cache/users";
import { apiRequest } from "@app/util/client";
import { IdentifierParseResult } from "@app/screens/login/IdentifierParser";
import { sentry } from "@app/util/sentry";
import isEqual from "react-fast-compare";

type UserEntry = { userId: string };
type IdentifierEntry = { identifier: IdentifierParseResult };
export type UserListEntry = UserEntry | IdentifierEntry;

export interface UserListEvents {
  change: { userIds: string[] };
  changeEntries: { entries: UserListEntry[] };
  add: {
    entriesAdded: UserListEntry[];
  };
  remove: { entriesRemoved: UserListEntry[] };
  lookupUsers: {
    identifiers: IdentifierParseResult[];
    lookupCompletedPromise: Promise<void>;
  };
  error:
    | {
        // API call failed
        reasonCode: "FAILED_TO_LOOKUP_ALL_USERS";
        identifiers: IdentifierParseResult[];
      }
    | {
        // API call succeeded but response did not include a user with a matching identifier
        reasonCode: "FAILED_TO_LOOKUP_USER";
        identifier: IdentifierParseResult;
      };
}

export interface UserListEventEmitter {
  on(
    eventName: keyof UserListEvents,
    handler: (event: UserListEvents[keyof UserListEvents]) => void
  ): this;

  off(
    eventName: keyof UserListEvents,
    handler: (...args: unknown[]) => void
  ): this;
}

export class UserListController
  extends EventEmitter
  implements UserListEventEmitter
{
  private readonly _entries: UserListEntry[] = [];
  private _prevEntriesReported: UserListEntry[] = [];
  private _prevUpstreamEntries: UserListEntry[] = [];
  private _prevUserIdsReported: string[] = [];

  constructor(upstreamEntries: UserListEntry[]) {
    super();
    this._entries = [];
    const initialEntries = this._add(upstreamEntries);
    this._prevUpstreamEntries = upstreamEntries;
    this._prevEntriesReported = initialEntries;
    this._prevUserIdsReported = this.getUserIds();
  }

  get entries(): UserListEntry[] {
    return _.cloneDeep(this._entries);
  }

  has(entry: UserListEntry): boolean {
    return this._entries.some((e) => entriesAreEqual(e, entry));
  }

  getUserIds(entries = this._entries): string[] {
    return entries
      .map((entry) => {
        if (isUserEntry(entry)) {
          return entry.userId;
        } else {
          return "";
        }
      })
      .filter(Boolean)
      .filter((userId, index, self) => self.indexOf(userId) === index)
      .sort();
  }

  reset(entries = this._prevUpstreamEntries): void {
    const { entriesRemoved, entriesAdded } = diffEntries(
      this._entries,
      entries
    );

    this._remove(entriesRemoved);
    this._add(entriesAdded);

    this.reportChanges();
  }

  add(entries: UserListEntry[]): void {
    const entriesAdded = this._add(entries);

    if (entriesAdded.length > 0) {
      this.emit("add", {
        entriesAdded,
      });
      this.reportChanges();
    }
  }

  private _add(entries: UserListEntry[]): UserListEntry[] {
    const cachedUsers = selectAllUsers(store.getState());

    const entriesToAdd = entries
      // Use a cached user if one exists for the identifier
      .map((entry) => {
        if (isIdentifierEntry(entry)) {
          const identifier = entry.identifier.value;
          const matchingCachedUser = cachedUsers.find((user) => {
            return user.email === identifier || user.phone === identifier;
          });
          if (matchingCachedUser) {
            return {
              userId: matchingCachedUser.id,
            };
          }
        }
        return entry;
      })
      // Remove duplicates
      .filter(
        (entry1, index, self) =>
          index === self.findIndex((entry2) => entriesAreEqual(entry1, entry2))
      )
      // Remove entries that are already in the list
      .filter(
        (entryToAdd) =>
          !this._entries.some((existingEntry) =>
            entriesAreEqual(existingEntry, entryToAdd)
          )
      );

    this._entries.push(...entriesToAdd);

    const identifiersToLookup = entriesToAdd
      .filter(isIdentifierEntry)
      .map(({ identifier }) => identifier);
    this.lookupUsersByIdentifier(identifiersToLookup);

    return entriesToAdd;
  }

  remove(entries: UserListEntry[]): void {
    const entriesRemoved = this._remove(entries);
    if (entriesRemoved.length > 0) {
      this.emit("remove", { entriesRemoved });
      this.reportChanges();
    }
  }

  private _remove(entries: UserListEntry[]): UserListEntry[] {
    const entriesRemoved = [];
    for (const entry of entries) {
      const entryIndex = this._entries.findIndex((e) =>
        entriesAreEqual(e, entry)
      );
      if (entryIndex !== -1) {
        entriesRemoved.push(this._entries[entryIndex]);
        this._entries.splice(entryIndex, 1);
      }
    }

    return entriesRemoved;
  }

  handleUpstreamEntriesChanged(upstreamEntries: UserListEntry[]): void {
    const prevUpstreamEntries = this._prevUpstreamEntries;
    this._prevUpstreamEntries = upstreamEntries;

    if (!isEqual(prevUpstreamEntries, upstreamEntries)) {
      const { entriesRemoved, entriesAdded } = diffEntries(
        prevUpstreamEntries,
        upstreamEntries
      );
      this._remove(entriesRemoved);
      this._add(entriesAdded);

      // Do not report upstream changes to avoid infinite loops
      this._prevUserIdsReported = this.getUserIds();
      this.reportChanges();
    }
  }

  private reportChanges() {
    const prevEntries = this._prevEntriesReported;

    if (!isEqual(prevEntries, this._entries)) {
      // Copy entries to prevent mutation outside the controller and reduce confusion as
      // the controller does mutate the entries which users would likely not expect.
      const entriesCopy = _.cloneDeep(this._entries);
      this._prevEntriesReported = entriesCopy;
      this.emit("changeEntries", { entries: entriesCopy });

      const prevUserIds = this._prevUserIdsReported;
      const currentUserIds = this.getUserIds();
      if (!_.isEqual(prevUserIds, currentUserIds)) {
        this._prevUserIdsReported = currentUserIds;
        this.emit("change", { userIds: currentUserIds });
      }
    }
  }

  private lookupUsersByIdentifier(identifiers: IdentifierParseResult[]) {
    if (identifiers.length === 0) {
      return;
    }

    const promise = apiRequest<CreateUsersResponse[]>("POST", "/users", {
      identifiers: identifiers.map((identifier) => identifier.value),
    })
      .then((users) => {
        const usersWithIdentifier = users.map((user) => {
          const matchedIdentifier = (user.matchedIdentifiers || [])[0];
          if (matchedIdentifier) {
            const identifier = identifiers.find(
              (i) => i.value === matchedIdentifier
            );
            if (identifier) {
              // Add the email or phone number to the user
              return {
                ...user,
                ...(identifier.type === "PHONE_NUMBER"
                  ? { mobile_number: identifier.value }
                  : identifier.type === "EMAIL_ADDRESS"
                  ? { email_address: identifier.value }
                  : {}),
              };
            }
          }
          return user;
        });
        store.dispatch(usersLoaded(usersWithIdentifier));

        const entriesAdded = [];
        for (const user of users) {
          const matchingIdentifierEntries = this._entries.filter(
            (e) =>
              isIdentifierEntry(e) &&
              user.matchedIdentifiers.includes(e.identifier.value)
          );

          const userIsAlreadyInList = this._entries.some(
            (e) => isUserEntry(e) && user.id === e.userId
          );
          if (!userIsAlreadyInList && matchingIdentifierEntries.length > 0) {
            const identifierEntryIndex = this._entries.indexOf(
              matchingIdentifierEntries[0]
            );
            const newUserEntry = {
              userId: user.id,
            };
            // replace identifier entry with user entry maintaining its position in the list
            this._entries.splice(identifierEntryIndex, 1, newUserEntry);
            entriesAdded.push(newUserEntry);
          }
          this._remove(matchingIdentifierEntries);
        }

        this.emit("add", {
          entriesAdded,
        });

        for (const identifier of identifiers) {
          // All identifiers should have been converted to user entries by now. Anything we find here was somehow missed.
          const entry = this._entries.find(
            (e) =>
              isIdentifierEntry(e) && identifier.value === e.identifier.value
          );
          if (entry) {
            sentry.addBreadcrumb({
              message: `Failed to lookup user. User was not found for identifier: ${identifier.format()}`,
              level: "error",
              data: {
                identifierValue: identifier.value,
              },
            });
            this.emit("error", {
              reasonCode: "FAILED_TO_LOOKUP_USER",
              identifier,
            });
            this._remove([entry]);
          }
        }
      })
      .catch((error) => {
        sentry.captureException(error, {
          extra: {
            message: `Failed to lookup all users!`,
            identifiers: identifiers.map((identifier) => identifier.value),
          },
        });
        this._remove(identifiers.map((identifier) => ({ identifier })));

        this.emit("error", {
          reasonCode: "FAILED_TO_LOOKUP_ALL_USERS",
          identifiers: identifiers,
        });
      })
      .finally(() => this.reportChanges());

    this.emit("lookupUsers", { identifiers, lookupCompletedPromise: promise });
  }
}

export const isIdentifierEntry = (
  user: UserEntry | IdentifierEntry
): user is IdentifierEntry => {
  return "identifier" in user && !!user.identifier;
};

export const isUserEntry = (
  user: UserEntry | IdentifierEntry
): user is UserEntry => {
  return "userId" in user && !!user.userId;
};

export function entriesAreEqual(
  entry1: UserEntry | IdentifierEntry,
  entry2: UserEntry | IdentifierEntry
): boolean {
  if ("userId" in entry1 && "userId" in entry2) {
    return entry1.userId === entry2.userId;
  } else if ("identifier" in entry1 && "identifier" in entry2) {
    return entry1.identifier.value === entry2.identifier.value;
  }
  return false;
}

function diffEntries(
  previousEntryList: UserListEntry[],
  nextEntryList: UserListEntry[]
) {
  return {
    entriesRemoved: previousEntryList.filter(
      (prevEntry) =>
        !nextEntryList.some((nextEntry) =>
          entriesAreEqual(nextEntry, prevEntry)
        )
    ),
    entriesAdded: nextEntryList.filter(
      (nextEntry) =>
        !previousEntryList.some((prevEntry) =>
          entriesAreEqual(prevEntry, nextEntry)
        )
    ),
  };
}
