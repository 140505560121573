import * as React from "react";
import {
  Keyboard,
  Platform,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import {
  createNavigatorFactory,
  EventMapBase,
  PartialRoute,
  Route,
  TabActions,
  TabRouter,
  useNavigationBuilder,
} from "@react-navigation/native";
import { EventEmitter } from "@react-navigation/core/src/types";
import Icon, { IconIdentifier } from "@app/components/icon";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { NavigationState, PartialState } from "@react-navigation/routers";
import {
  getHrefForInternalLink,
  LinkableLocation,
  OnLinkPress,
} from "@app/util/link.utils";
import styled from "styled-components/native";
import BasePressable from "@app/components/questkit/BasePressable";
import Text from "@app/components/questkit/text";
import isEqual from "react-fast-compare";
import { useMemo } from "react";

interface TabNavigatorProps {
  initialRouteName: string;
  children: React.ReactElement | null;
  screenOptions: {
    title: string;
    tabTitle: string;
    tabTitleIcon?: IconIdentifier;
    testID?: string;
  };
  tabBarStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

function TabNavigator({
  initialRouteName,
  children,
  screenOptions,
  tabBarStyle,
  contentStyle,
}: TabNavigatorProps) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  const routeProps = useMemo(() => {
    const routesToState = findRouteToState(
      navigationRef.getRootState(),
      state.key
    );

    return state.routes.map((route) => {
      const onPress = (pressEvent: Parameters<OnLinkPress>[0]) => {
        const event = (
          navigation as EventEmitter<{
            tabPress: {
              canPreventDefault: true;
            };
          }>
        ).emit({
          type: "tabPress",
          target: route.key,
          canPreventDefault: true,
        });

        if (!event.defaultPrevented) {
          Keyboard.dismiss();
          navigation.dispatch({
            ...TabActions.jumpTo(route.name),
            target: state.key,
          });
          pressEvent?.preventDefault();
          return false;
        }
      };
      let href;
      if (routesToState) {
        href = getHrefForInternalLink({
          screen: routesToState[0].name,
          params: {
            ...routesToState[0].params,
            screen: route.name,
          },
        } as LinkableLocation);
      }

      onPress.linkProps = {
        accessibilityRole: "link" as const,
        href,
      };

      return {
        onPress,
      };
    });
  }, [navigation, state.key, state.routes]);

  return (
    <NavigationContent>
      <TabBar style={[tabBarStyle]} accessibilityRole={"tablist"}>
        {state.routes.map((route, index) => {
          return (
            <TabButton
              key={route.key}
              active={state.index === index}
              title={descriptors[route.key].options.tabTitle || route.name}
              titleIcon={descriptors[route.key].options.tabTitleIcon}
              testID={descriptors[route.key].options.testID}
              onPress={routeProps[index].onPress}
            />
          );
        })}
      </TabBar>
      <TabContentWrapper style={[contentStyle]}>
        {state.routes.map((route, index) => (
          <TabViewWrapper key={route.key} isActiveRoute={index === state.index}>
            {descriptors[route.key].render()}
          </TabViewWrapper>
        ))}
      </TabContentWrapper>
    </NavigationContent>
  );
}

const TabContentWrapper = styled.View`
  flex: 1;
`;

const TabViewWrapper = styled.View<{ isActiveRoute: boolean }>`
  flex: 1;
  display: ${({ isActiveRoute }) => (isActiveRoute ? undefined : "none")};
`;

// This feels silly. We just need to know the parent route(s) to determine the path to the tab route.
function findRouteToState(
  state: NavigationState | PartialState<NavigationState> | undefined,
  navigationKey: string,
  routes: (Route<string> | PartialRoute<Route<string>>)[] = []
): PartialRoute<Route<string>>[] | null {
  if (state?.key === navigationKey) {
    return routes;
  }
  if (Array.isArray(state?.routes)) {
    for (const route of state!.routes) {
      if (route.state) {
        const routeToState = findRouteToState(route.state, navigationKey, [
          ...routes,
          route,
        ]);
        if (routeToState) {
          return routeToState;
        }
      }
    }
  }
  return null;
}

export const createTabNavigator = createNavigatorFactory<
  NavigationState,
  TabNavigatorProps["screenOptions"],
  EventMapBase,
  typeof TabNavigator
>(TabNavigator);

const TabButton: React.FC<{
  title: string;
  titleIcon?: IconIdentifier;
  active: boolean;
  onPress: OnLinkPress | (() => void);
  testID?: string;
}> = React.memo(({ title, titleIcon, active, onPress, testID }) => {
  return (
    <TabBarItemWrapper
      active={active}
      onPress={onPress}
      testID={testID}
      accessibilityRole={"tab"}
    >
      {titleIcon ? (
        <IconWrapper>
          <StyledIcon icon={titleIcon} active={active} container="COLLAPSED" />
        </IconWrapper>
      ) : null}
      <TabBarItemText size={"small"} active={active}>
        {title}
      </TabBarItemText>
    </TabBarItemWrapper>
  );
}, isEqual);
TabButton.displayName = "TabBarItem";

const TabBar = styled.View`
  flex-direction: row;
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${({ theme }) => theme.textInput.normal.border};
  padding: 8px;
  gap: 8px;
  border-radius: 57px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 4px;
`;

const TabBarItemWrapper = styled(BasePressable)<{ active: boolean }>`
  height: 40px;
  width: 148px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ theme, active }) =>
    active
      ? theme.button.primary.background
      : theme.tile.standard.background.normal};
`;

const StyledIcon = styled(Icon)<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.button.primary.text : theme.textInput.normal.text};
`;

const IconWrapper = styled.View`
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

const TabBarItemText = styled(Text)<{ active: boolean }>`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
  color: ${({ theme, active }) =>
    active ? theme.button.primary.text : theme.textInput.normal.text};
`;
