import { Text } from "@app/components/questkit";
import { colors } from "@app/themes/Colors";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SafeAreaView from "react-native-safe-area-view";
import styled from "styled-components/native";
import { View } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import QKModal from "@app/components/modal";
import ItemOptionsDialog, {
  itemTypeViewDataList,
  itemTypeViewDataMap,
} from "@app/components/modal/itemOptionsDialog";
import {
  EditLevel,
  QuestView,
  ReviewButtonBehavior,
  StatusMessageBehavior,
  SubmitButtonBehaviour,
} from "@app/components/screen/quest/common/questView";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { IconIdentifier } from "@app/components/icon";
import {
  PartialCompletionActionData,
  useCompletionActions,
} from "@app/quest/edit/completionActions";
import { PartialItemData, useItems } from "@app/quest/edit/items";
import Button from "@app/components/questkit/button";
import { Boundary } from "../boundary";
import QKScrollView, {
  type QKScrollViewController,
} from "@app/components/questkit/ScrollView";
import { ItemType } from "@questmate/openapi-spec";
import { useModal } from "@app/components/modal/ModalManager";
import { useEffectOnce } from "@app/util/useEffectOnce";
import StartQuestDialog from "@app/components/modal/startQuestDialog";
import { useFocusableRef, useFocusController } from "@app/util/focus";
import {
  DropdownOption,
  ModalCard,
} from "@app/components/questkit/dropdownWithModal";
import {
  completionActionTypeViewDataList,
  completionActionTypeViewDataMap,
} from "@app/components/modal/completionActionOptionsDialog";
import { CompletionActionType } from "@app/types/completionActionRenderData";
import { Image } from "expo-image";
import { useRequest } from "@app/util/client/requests";
import { fetchLibraryQuestScripts } from "@app/util/client/requests/library";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import ConditionalWrap from "conditional-wrap";
import { QuestStartTrigger } from "@app/quest/start/QuestStartTrigger";
import {
  QuestSectionHeader,
  QuestSectionHeaderDescriptionText,
} from "@app/components/screen/quest/questSectionHeader";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";

export type ManageQuestViewMode =
  // For editing on Quest screen
  | "EDIT"
  // For previewing on Quest screen
  | "READ_ONLY";

interface QuestManageViewProps {
  viewMode?: ManageQuestViewMode;
}

export const QuestManageView: React.FC<QuestManageViewProps> = ({
  viewMode: parentViewMode = "READ_ONLY",
}) => {
  const { data: libraryQuestScripts } = useRequest(fetchLibraryQuestScripts());
  useEffect(() => {
    if (Array.isArray(libraryQuestScripts)) {
      const logoIconUrls = libraryQuestScripts
        .flatMap(({ integrations }) => integrations ?? [])
        .map(({ logoIcon }) => logoIcon?.url)
        .filter((url) => !!url);
      Image.prefetch(logoIconUrls);
    }
  }, [libraryQuestScripts]);

  const {
    templateId,
    questPrototypeId,
    useQuestPrototypeWithChanges,
    addChange,
    markAllFieldsAsTouched,
    advancedMode,
  } = useQuestViewContext(["MANAGE"]);
  const questPrototype = useQuestPrototypeWithChanges((qp) => {
    return {
      id: qp.id,
      name: qp.name,
      startTriggers: qp.startTriggerIds?.map(
        (id) => qp.startTriggersById![id]!
      ),
      hasItems: qp.itemIds && qp.itemIds.length > 0,
      introText: qp.introText,
      parentItemPrototypeId: qp.parentItemPrototypeId,
    };
  });

  const firstStartTriggerType = questPrototype.startTriggers?.[0]?.type;

  const { openModal: openStartQuestModal, addListener: addStartModalListener } =
    useModal(({ showModal, setShowModal }) => (
      <QKModal
        testID="start-quest-modal"
        showModal={showModal}
        setShowModal={setShowModal}
        title={
          firstStartTriggerType === "KIOSK"
            ? "Logout & Start Kiosk Mode"
            : "New Quest Run"
        }
      >
        <StartQuestDialog
          templateId={templateId}
          rootQuestPrototypeId={questPrototypeId}
          name={questPrototype.name}
          setShowStartQuestModal={setShowModal}
        />
      </QKModal>
    ));

  useEffectOnce(() =>
    addStartModalListener((event) => {
      if ("showModal" in event.updatedFields) {
        if (event.updatedFields.showModal) {
          Analytics.trackEvent("Open Start New Run Dialog", {
            screen: "Quest Template",
            questPrototypeId,
            questType: firstStartTriggerType!,
          });
        } else {
          Analytics.trackEvent("Close Start New Run Dialog");
        }
      }
    })
  );

  const [showItemOptionsModal, setShowItemOptionsModal] = useState<
    number | undefined
  >();

  const [viewMode, setViewMode] =
    useSyncedState<ManageQuestViewMode>(parentViewMode);

  const onIntroTextChange = useCallback(
    (updatedIntroText: string) => {
      addChange((draft) => {
        draft.introText = updatedIntroText;
      });
    },
    [addChange]
  );

  const {
    completionActions,
    onCompletionActionAdded,
    onCompletionActionDelete,
    onCompletionActionReorder,
    onCompletionActionChange,
    onCompletionActionValidationContextChange,
    onCompletionActionTouched,
  } = useCompletionActions();

  const { items, onItemAdded, onItemDelete, onItemReorder, onItemChange } =
    useItems();

  const onShowItemOptions = useCallback(
    (index: number) => {
      Analytics.trackEvent("Open Item Options Dialog");
      setShowItemOptionsModal(index);
    },
    [setShowItemOptionsModal]
  );

  const { openModal: showAddItemModal } = useModal(
    ({ setShowModal, showModal }) => {
      const modalSearchInputRef = useFocusableRef("item-picker-search-input");
      const focusController = useFocusController();
      useEffect(() => {
        focusController.focus("item-picker-search-input");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const options = useMemo(
        () => [
          ...itemTypeViewDataList
            .filter(({ isAdvanced }) => !isAdvanced || advancedMode)
            .map(({ value, label, icon, searchTerms }) => {
              return {
                key: value,
                value,
                name: label,
                icon,
                searchTerms,
              };
            }),
          ...(libraryQuestScripts ?? [])
            .filter(({ useType }) => useType === "item")
            .map(({ id, name, integrations }) => {
              const iconUrl = integrations?.[0]?.logoIcon?.url;
              return {
                key: id,
                value: `SCRIPT_REF:${id}`,
                name,
                icon: iconUrl
                  ? {
                      url: iconUrl,
                    }
                  : ("terminal-prompt" as const),
                searchTerms: integrations?.map(({ name }) => name) ?? [],
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [libraryQuestScripts, advancedMode]
      );
      const newItemPosition = items.length;
      const onSelectOption = useCallback(
        (option: DropdownOption) => {
          const itemData: PartialItemData = {};
          if (`${option.value}`.startsWith("SCRIPT_REF:")) {
            itemData.customScript = option.value as string;
            itemData.type = "CustomV2";
            itemData.name = option.name;
          } else {
            itemData.type = option.value as ItemType;
            if (itemData.type === "CustomV2") {
              itemData.customScript = "SCRIPT_REF:custom-item-initial-script";
            }
          }

          const newItemId = onItemAdded(newItemPosition, itemData);
          setShowModal(false);
          // Unfortunately, this is a bit variable across platforms and can be affected by lag.
          const delayForModalDismissAnimationToComplete = 650;
          setTimeout(
            () => focusController.focus(newItemId),
            delayForModalDismissAnimationToComplete
          );
        },
        [setShowModal, focusController, newItemPosition]
      );
      return (
        <QKModal
          title="Choose Item Type"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <ModalCard
            selectedOption={null}
            onSelectOption={onSelectOption}
            options={options}
            searchInputRef={modalSearchInputRef}
            initialRenderCount={options.length}
          />
        </QKModal>
      );
    }
  );

  const { openModal: showAddCompletionActionModal } = useModal(
    ({ setShowModal, showModal }) => {
      const modalSearchInputRef = useFocusableRef(
        "completion-action-picker-search-input"
      );
      const focusController = useFocusController();
      useEffectOnce(() => {
        focusController.focus("completion-action-picker-search-input");
      });
      const options = useMemo(
        () => [
          ...completionActionTypeViewDataList
            .filter(({ isAdvanced }) => !isAdvanced || advancedMode)
            .map(({ value, label, icon, searchTerms }) => {
              return {
                key: value,
                value,
                name: label,
                icon,
                searchTerms,
              };
            }),
          ...(libraryQuestScripts ?? [])
            .filter(({ useType }) => useType === "completion_action")
            .map(({ id, name, integrations }) => {
              const iconUrl = integrations?.[0]?.logoIcon?.url;
              return {
                key: id,
                value: `SCRIPT_REF:${id}`,
                name,
                icon: iconUrl
                  ? {
                      url: iconUrl,
                    }
                  : ("terminal-prompt" as const),
                searchTerms: integrations?.map(({ name }) => name) ?? [],
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [libraryQuestScripts, advancedMode]
      );

      const newCompletionActionPosition = completionActions.length;
      const onSelectOption = useCallback(
        (option: DropdownOption) => {
          let data: PartialCompletionActionData;
          if (`${option.value}`.startsWith("SCRIPT_REF:")) {
            data = {
              name: option.name,
              type: "CustomV2",
              customScript: option.value as string,
            };
          } else {
            data = { type: option.value as CompletionActionType };
            if (data.type === "CustomV2") {
              data.customScript =
                "SCRIPT_REF:custom-completion-action-initial-script";
            }
          }

          onCompletionActionAdded(newCompletionActionPosition, data);
          setShowModal(false);
        },
        [setShowModal, newCompletionActionPosition]
      );

      return (
        <QKModal
          title="Choose Completion Action Type"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <ModalCard
            selectedOption={null}
            onSelectOption={onSelectOption}
            options={options}
            searchInputRef={modalSearchInputRef}
          />
        </QKModal>
      );
    }
  );

  // const introTextAvailable = Boolean(questPrototype.introText);
  // const fadeInDelay = introTextAvailable ? 650 : 250;

  const addItemCardIcons = useMemo((): IconIdentifier[] => {
    const featuredItemTypes: ItemType[] = [
      "MultiLineText",
      "Checklist",
      "Rating",
      "DateTime",
      "FileUpload",
      "Signature",
    ];
    const featuredItemIcons = featuredItemTypes.map(
      (itemType) => itemTypeViewDataMap[itemType].icon
    );

    const featuredIntegrationIcons =
      libraryQuestScripts
        ?.filter(({ useType }) => useType === "item")
        .flatMap(({ integrations }) => integrations ?? [])
        .filter(({ featured }) => featured)
        .filter(
          // remove duplicates
          (integration, index, self) =>
            self.findIndex(({ appId }) => appId === integration.appId) === index
        )
        .map(({ logoIcon }) => ({ url: logoIcon?.url } as IconIdentifier)) ??
      [];

    return [...featuredItemIcons, ...featuredIntegrationIcons];
  }, [libraryQuestScripts]);

  const addCompletionActionCardIcons = useMemo((): IconIdentifier[] => {
    const featuredCompletionActionTypes: CompletionActionType[] = [
      "TEXT",
      "TREMENDOUS_REWARD",
      "POST_SLACK_MESSAGE",
      "CustomV2",
    ];
    const featuredCompletionActionIcons = featuredCompletionActionTypes.map(
      (itemType) => completionActionTypeViewDataMap[itemType].icon
    );

    const featuredIntegrationIcons =
      libraryQuestScripts
        ?.filter(({ useType }) => useType === "completion_action")
        .flatMap(({ integrations }) => integrations ?? [])
        .filter(({ featured }) => featured)
        .filter(
          // remove duplicates
          (integration, index, self) =>
            self.findIndex(({ appId }) => appId === integration.appId) === index
        )
        .map(({ logoIcon }) => ({ url: logoIcon?.url } as IconIdentifier)) ??
      [];

    return [...featuredCompletionActionIcons, ...featuredIntegrationIcons];
  }, [libraryQuestScripts]);

  const startTriggers = useMemo(() => {
    if (questPrototype.parentItemPrototypeId) {
      // is subquest
      return;
    }
    return questPrototype.startTriggers;
  }, [questPrototype.parentItemPrototypeId, questPrototype.startTriggers]);
  const [isChangingQuestType, setIsChangingQuestType] = useState(false);

  const scrollViewControllerRef = useRef<QKScrollViewController>(null);
  const changeToEditView = useCallback(() => {
    setViewMode("EDIT");
    scrollViewControllerRef.current?.scrollToStart(false);
    Analytics.trackEvent("Edit Quest Template");
  }, [setViewMode]);
  const changeToReadOnlyView = useCallback(() => {
    markAllFieldsAsTouched();
    setViewMode("READ_ONLY");
    scrollViewControllerRef.current?.scrollToStart(false);
    Analytics.trackEvent("Save Quest Template");
  }, [markAllFieldsAsTouched, setViewMode]);
  const hasValidationErrors = useQuestHasValidationErrors();

  const hasErrors = hasValidationErrors || !questPrototype.hasItems;

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questPrototype",
        uploadContextId: questPrototypeId,
        contexts: [
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <View style={{ flex: 1, position: "relative" }}>
        <StyledScrollView
          keyboardShouldPersistTaps="always"
          ref={scrollViewControllerRef}
        >
          <SafeAreaView
            forceInset={{
              top: "never",
              bottom: "always",
            }}
          >
            {viewMode === "READ_ONLY" ? (
              <Boundary paddingHorizontal={20}>
                {isChangingQuestType ? null : (
                  <QuestSectionHeader
                    text="Start your Quest"
                    description={
                      <QuestSectionHeaderDescriptionText size="medium">
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          Manually{" "}
                        </QuestSectionHeaderDescriptionText>
                        assign it to yourself or others. Start automatically on
                        a{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          schedule
                        </QuestSectionHeaderDescriptionText>
                        , via a{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          share link
                        </QuestSectionHeaderDescriptionText>{" "}
                        or by{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          another app
                        </QuestSectionHeaderDescriptionText>
                        .
                      </QuestSectionHeaderDescriptionText>
                    }
                    style={{ marginTop: 40 }}
                  />
                )}
                {startTriggers && startTriggers.length > 0 ? (
                  <QuestStartTrigger
                    startTrigger={startTriggers![0]}
                    // TODO: Add ability to change Quest type.
                    isChangingQuestType={isChangingQuestType}
                    setIsChangingQuestType={setIsChangingQuestType}
                    openStartQuestModal={openStartQuestModal}
                    readOnly={false}
                  />
                ) : null}
              </Boundary>
            ) : null}
            {isChangingQuestType ? null : (
              <ConditionalWrap
                condition={viewMode === "READ_ONLY"}
                wrap={(children) => (
                  <Boundary paddingHorizontal={20}>
                    <ReadOnlyPreviewContainer>
                      <ReadOnlyPreviewHeader hasErrors={hasErrors}>
                        <PreviewText size={"mediumLargeBold"}>
                          Preview
                        </PreviewText>
                        <EditPreviewButton
                          buttonType={"secondary"}
                          icon={"pen"}
                          title={hasErrors ? "Fix Errors" : "Edit"}
                          onPress={changeToEditView}
                          testID={"edit-template-button"}
                        />
                      </ReadOnlyPreviewHeader>
                      <ReadOnlyPreviewQuestContainer hasErrors={hasErrors}>
                        {hasErrors ? (
                          <ErrorWrapper>
                            <ErrorIcon icon="info" />
                            <Text size="medium" $warning>
                              {questPrototype.hasItems
                                ? "This Quest has errors that require your attention!"
                                : "This Quest has no items."}
                            </Text>
                          </ErrorWrapper>
                        ) : null}
                        {questPrototype.introText ? null : (
                          <View style={{ height: 20 }} />
                        )}
                        {children}
                      </ReadOnlyPreviewQuestContainer>
                    </ReadOnlyPreviewContainer>
                  </Boundary>
                )}
              >
                <>
                  {viewMode === "EDIT" ? <View style={{ height: 80 }} /> : null}
                  <QuestView
                    startTriggers={[]}
                    openStartQuestModal={openStartQuestModal}
                    introText={questPrototype.introText}
                    onIntroTextChange={onIntroTextChange}
                    items={items}
                    onItemChange={onItemChange}
                    onItemAdded={onItemAdded}
                    onItemDelete={onItemDelete}
                    onItemReorder={onItemReorder}
                    onShowItemOptions={onShowItemOptions}
                    onAddItemPressed={showAddItemModal}
                    addItemCardIcons={addItemCardIcons}
                    loading={false}
                    completed={false}
                    submitButtonTitle={"Submit"}
                    itemsEditLevel={
                      viewMode === "EDIT"
                        ? EditLevel.Editable
                        : EditLevel.Readonly
                    }
                    completionActionsEditLevel={
                      viewMode === "EDIT"
                        ? EditLevel.Editable
                        : EditLevel.Interactive
                    }
                    submitButtonBehaviour={
                      viewMode === "READ_ONLY"
                        ? SubmitButtonBehaviour.Disabled
                        : SubmitButtonBehaviour.Hidden
                    }
                    reviewButtonBehavior={ReviewButtonBehavior.Hidden}
                    statusMessageBehavior={StatusMessageBehavior.Hidden}
                    canEditIntroText={viewMode === "EDIT"}
                    sectionHeaders={{
                      startTriggers: "HIDDEN",
                      items: viewMode === "EDIT" ? "DETAILED" : "HIDDEN",
                      completionActions:
                        viewMode === "EDIT"
                          ? "DETAILED"
                          : completionActions.length > 0
                          ? "MINIMAL"
                          : "HIDDEN",
                    }}
                    completionActions={completionActions}
                    onCompletionActionChange={onCompletionActionChange}
                    onCompletionActionAdded={onCompletionActionAdded}
                    onCompletionActionDelete={onCompletionActionDelete}
                    onCompletionActionReorder={onCompletionActionReorder}
                    onCompletionActionValidationContextChange={
                      onCompletionActionValidationContextChange
                    }
                    onCompletionActionTouched={onCompletionActionTouched}
                    onAddCompletionActionPressed={showAddCompletionActionModal}
                    addCompletionActionCardIcons={addCompletionActionCardIcons}
                  />
                  <View style={{ height: viewMode === "READ_ONLY" ? 0 : 80 }} />
                </>
              </ConditionalWrap>
            )}
          </SafeAreaView>
        </StyledScrollView>
        {viewMode === "EDIT" ? (
          <SaveButtonContainer>
            <Boundary paddingHorizontal={20}>
              <Button
                title={"Save Changes"}
                buttonType={"action"}
                testID="save-template-button"
                icon={"checkmark"}
                onPress={changeToReadOnlyView}
                style={{ width: 200, alignSelf: "center" }}
              />
            </Boundary>
          </SaveButtonContainer>
        ) : null}
      </View>

      <QKModal
        showModal={showItemOptionsModal !== undefined}
        setShowModal={() => setShowItemOptionsModal(undefined)}
        title="Item Options"
      >
        <ItemOptionsDialog
          item={items[showItemOptionsModal!]}
          parentQuestType={firstStartTriggerType ?? null}
          onItemChange={onItemChange}
          onModalClose={() => setShowItemOptionsModal(undefined)}
        />
      </QKModal>
    </MediaContext.Provider>
  );
};

const ReadOnlyPreviewContainer = styled(Boundary)`
  margin-top: 20px;
  margin-bottom: 40px;
`;
const PreviewText = styled(Text)`
  color: ${colors.white};
  align-self: center;
`;
const EditPreviewButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
const ReadOnlyPreviewHeader = styled.View<{
  hasErrors: boolean;
}>`
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 56px;
  background-color: ${({ hasErrors }) =>
    hasErrors ? colors.red900 : colors.blue950};
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
`;
const ReadOnlyPreviewQuestContainer = styled.View<{
  hasErrors: boolean;
}>`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-width: 1px;
  border-color: ${({ hasErrors }) =>
    hasErrors ? colors.red900 : colors.blue950};
  transition: border-color 0.3s ease-in-out;
`;

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const SaveButtonContainer = styled.View`
  position: absolute;
  justify-content: center;
  top: 20px;
  width: 100%;
`;

const useQuestHasValidationErrors = () => {
  const { useScopedValidationErrors } = useQuestViewContext(["MANAGE"]);
  const errors = useScopedValidationErrors([]);

  return errors.length !== 0;
};

const ErrorWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  align-self: center;
`;

const ErrorIcon = styled(ItemInfoIcon)`
  color: ${({ theme }) => theme.warning};
`;
