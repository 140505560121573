import TrackPlayer, { Event } from "react-native-track-player";
import { createDebugLogger } from "@app/config/logger";
import { ENV } from "@app/config/env";

const debug = createDebugLogger(
  "BackgroundPlaybackService",
  () => ENV.logLevels.trackPlayer === "debug"
);

export const PlaybackService = async function () {
  TrackPlayer.addEventListener(Event.RemotePlay, () => {
    debug("Received RemotePlay event");
    void TrackPlayer.play();
  });

  TrackPlayer.addEventListener(Event.RemoteSkip, () => {
    debug("Received RemoteSkip event");
    void TrackPlayer.skipToNext();
  });

  TrackPlayer.addEventListener(Event.RemotePrevious, () => {
    debug("Received RemotePrevious event");
    void TrackPlayer.skipToPrevious();
  });

  TrackPlayer.addEventListener(Event.RemotePause, () => {
    debug("Received RemotePause event");
    void TrackPlayer.pause();
  });
};
